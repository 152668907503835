import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { SideBar } from '../../ui/sidebar/sidebar';
import { Header } from '../../ui/header/header';
import { useDidMount } from '../../../hooks/life-cycle';
import { useAppSelector } from '../../../app/hooks';
import { apiCompany, ApiManager } from '../../../managers/api-manager';
import { RoutingPath } from '../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { dialogAction } from '../../../slices/dialog-slice';
import { push } from 'connected-react-router';
import { loginCompanyUserInfoAction } from '../../../slices/login-company-user-info-slicer';
import dummy from '../../../assets/images/common/icon_user.svg';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';

// バリデーション用意
const validations: { [key: string]: Validation } = {
  length80: ValidationFactory('length80'),
  require: ValidationFactory('requireEdit'),
};

const UserSetting = () => {
  const dispatch = useDispatch();
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);
  const { companyUserIcon } = useAppSelector((state) => state.loginCompanyUserInfo);
  const [displayName, setDisplayName] = useState('');
  const [icon, setIcon] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChangeDisplayName = useCallback(
    (v) => {
      setDisplayName(v);
    },
    [],
  );
  /** アイコンクリックでアイコンを変更 */
  const handleClickIconImage = useCallback(() => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  }, []);
  /** アイコンの変更 */
  const handleChangeIcon = useCallback(
    (e) => {
      // 選択されなかったらリターンする
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      // 画像ファイル以外は除く
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['jpeg/png ファイルを選択してください'],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogAction.pop());
            },
          }],
        }));
        return;
      }
      // 画像の表示
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setIcon(reader.result as string);
      };
    },
    [],
  );

  // バリデーションのトータルチェック
  // const isDisabled = CheckAllValid(validations);
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  const [validFlag, setValidFlag] = useState(false);

  const handleClickEdit = useCallback(() => {
    (async () => {
      setIsDisabled(true)
      await apiCompany.profiles().post({
        display_name: displayName,
      }).then(() => {
        dispatch(loginCompanyUserInfoAction.setCompanyUserDetail({
          companyUserInfo: {
            ...companyUserInfo,
            display_name: displayName,
          },
        }));
        if (!icon) {
          dispatch(dialogAction.pushMessage({
            title: '確認',
            message: ['更新しました'],
            buttons: [{
              label: 'OK',
              callback: () => {
                dispatch(dialogAction.pop());
                dispatch(push(RoutingPath.companyStatus));
              },
            }],
          }));
        }
      }).catch((e) => {
        ApiManager.errorFunc(e)
        setIsDisabled(false)
      });
      if (icon) {
        // // 現在のアイコンを削除
        // await apiCompany.icon().delete()
        //   .then(() => {
        //     dispatch(loginCompanyUserInfoAction.setCompanyUserIcon({
        //       companyUserIcon: {
        //         Filedata: '',
        //       },
        //     }));
        //   }).catch((e) => {
        //     ApiManager.errorFunc(e);
        //     setIsDisabled(false)
        //   });
        // 新しいアイコンを設定
        await apiCompany.icon().post({
          Filedata: icon,
        }).then(() => {
          dispatch(loginCompanyUserInfoAction.setCompanyUserIcon({
            companyUserIcon: {
              Filedata: icon,
            },
          }));
          dispatch(dialogAction.pushMessage({
            title: '確認',
            message: ['更新しました'],
            buttons: [{
              label: 'OK',
              callback: () => {
                dispatch(dialogAction.pop());
                dispatch(push(RoutingPath.companyStatus));
              },
            }],
          }));
        }).catch((e) => {
          ApiManager.errorFunc(e);
          setIsDisabled(false)
        });
      }
    })();
  }, [displayName, icon]);

  useDidMount(() => {
    if (!companyUserInfo.member_id) {
      return;
    }
    // 表示名の初期値を取得
    setDisplayName(companyUserInfo.display_name);
    // apiCompany.members(companyUserInfo.member_id).get()
    //   .then((v: any) => {
    //     setDisplayName(v.body.data.display_name);
    //   });
    // アイコンを取得
    setIcon(companyUserIcon.Filedata);
    apiCompany.icon().get()
      .then((v) => {
        setIcon(v.body.data.Filedata || '');
      });
  });

  /** バリデーション更新 */
  useDidMount(() => {
    setValidFlag(true);
  });
  useEffect(() => {
    validations['length80'] = ValidationFactory('length80');
    validations['require'] = ValidationFactory('requireEdit');
  }, [validFlag]);

  useEffect(() => {
    setIsDisabled(CheckAllValid(validations))
  }, [displayName]);


  return (
    <div
      id="App"
      className="setting"
    >
      <SideBar
        currentPage=""
        apiManger={'company'}
      />
      <div className="main_cnt">
        <Header apiManger={'company'} />
        <div className="inner">
          <section className="">
            <header>
              <h2>ユーザー設定</h2>
            </header>
            <div className="edit_box">
              <div className="item_wrap icon_edit">
                <div className="item_head">
                  アイコン
                </div>
                <div className="item_cnt">
                  <img
                    src={icon ? icon : dummy}
                    style={{ cursor: 'pointer', marginLeft: '70px' }}
                    alt={`${displayName}さん`}
                    onClick={handleClickIconImage}
                  />
                  <Input
                    type="file"
                    accept={'image/jpeg, image/png'}
                    style={{ display: 'none' }}
                    onChange={(e) => handleChangeIcon(e)}
                    ref={fileInputRef}
                  />
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_head">
                  表示名変更
                  <span
                    className="required"
                    style={{ marginLeft: '5px' }}
                  >必須</span>
                </div>
                <div className="item_cnt">
                  <Input
                    value={displayName}
                    onChange={(e) => handleChangeDisplayName(e.target.value)}
                    validations={[
                      validations.require,
                      validations.length80,
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="btn_box align_center">
              <Button
                size="large"
                label="設定を変更する"
                onClick={handleClickEdit}
                disabled={isDisabled}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default UserSetting;
