import React, { useCallback } from 'react';
import { TableElement } from './table-element';
import './table.scss';
import { useDidMount } from '../../hooks/life-cycle';

type AlignType = 'l' | 'c' | 'r';

export type SortListType = { index: number, type: 'up' | 'down' | '', key: number, active: 'active' | '', callback: (v: 'up' | 'down' | '') => void; };

type props = {
  head: (string | JSX.Element)[],
  // head: TableElement[],
  body: TableElement[][],
  /** セルの左寄せ、中央よせ、右寄せ */
  alignList: AlignType[],
  /** テーブルのスタイル */
  tableStyle?: 'table' | 'sub_table' | '',
  /** セルのスタイル */
  cellStyle?: 'table_striped' | '',
  /** テーブルのレイアウト */
  layoutStyle?: 'table_sticky' | '',
  /** テーブルの選択可否 */
  selectStyle?: 'table_selectable' | '',
  sortList?: SortListType[],
  setSortBy: React.Dispatch<React.SetStateAction<number>>,
  setHighlow: React.Dispatch<React.SetStateAction<number>>,
  setIndex?: React.Dispatch<React.SetStateAction<number>>,
  setActive?: React.Dispatch<React.SetStateAction<string>>,
  onClickRow?: (i: number) => void,
  _ref?: React.Ref<HTMLDivElement>,
};

const getAlignClassName = (alignType: AlignType) => {
  switch (alignType) {
    case 'l':
      return 'align_left';
    case 'c':
      return 'align_center';
    case 'r':
      return 'align_right';
  }
};

export const Table = (props: props) => {
  const {
    head: _head,
    body,
    tableStyle,
    cellStyle,
    layoutStyle,
    selectStyle,
    alignList,
    sortList,
    setSortBy,
    setHighlow,
    setIndex,
    setActive,
    onClickRow,
    _ref,
  } = props;

  const handleClickTableSort = useCallback(
    (sortBy: number, highlow: string, i: number) => {
      const num = highlow === 'up' ? 1 : 0;
      setSortBy!(sortBy);
      setHighlow!(num);
      setIndex!(i);
      setActive!('active');
    },
    [],
  );

  const head: { label: string | JSX.Element, type: 'up' | 'down' | '', key: number | undefined, active: 'active' | '', callback?: (v: 'up' | 'down' | '') => void; }[] = [..._head].map((v, i) => {
    const sort = (sortList || []).find((s) => s.index === i);
    return {
      label: v,
      type: sort?.type || '',
      key: sort?.key || 1,
      active: sort?.active || '',
      callback: sort?.callback,
    };
  });
  useDidMount(() => {
    if (head.length !== alignList.length) {
      throw new Error('head と alignList の要素数が異なります。\nalignList は要素数分全てを定義してください。');
    }
  });
  return (
    <>
      <div className="table_wrapper table_responsive" ref={_ref}>
        <table  className={`${tableStyle} ${cellStyle} ${layoutStyle} ${selectStyle}`}>
          <thead>
          <tr>
            {head.map((v, i) => (
              <th
                className={v.active && 'selected'}
                key={`table_head_${String(v.label)}_${i}`}
                onClick={() => {
                  if (v.callback) {
                    v.callback(v.type);
                  }
                }}
              >
                {v.label}
                {v.type === 'up' ? (
                  <i
                    className="up on"
                    onClick={() => handleClickTableSort(v.key!, v.type, i)}
                  />
                ) : (<></>)}
                {v.type === 'down' ? (
                  <i
                    className="down on"
                    onClick={() => handleClickTableSort(v.key!, v.type, i)}
                  />
                ) : (<></>)}
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {body.map((v, i) => (
            <tr
              key={`table_${String(v)}_${i}`}
              className={(v[v.length - 1] === 'stop') ? 'grayed_out' : ''}
              onClick={() => onClickRow?.(i)}
            >
              {/* <tr key={`table_${String(v)}_${i}`} className={(v[v.length - 1] === 'stop') ? 'disabled' : ''}> */}
              {v.map((w, j) => (
                (v[v.length - 1]) === 'stop' || (v[v.length - 1]) === 'active' ?
                  (j !== v.length - 1) && <td
                    className={getAlignClassName(alignList[j])}
                    key={`table_${w}_${j}`}
                  >{w}</td> :
                  <td
                    className={getAlignClassName(alignList[j])}
                    key={`table_${w}_${j}`}
                  >{w}</td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
// ---------------------------------------- defaultProps ----------------------------------------
Table.defaultProps = {
  tableStyle: 'table',
  cellStyle: 'table_striped',
  layoutStyle: 'table_sticky',
  selectStyle: 'table_selectable',
};
