import React, { useCallback, useEffect, useState } from 'react';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import logo from '../../../assets/images/common/p-loop_logo_title.png';
import logo_kanri from '../../../assets/images/common/logo-kanri.svg';
import '../../../App.scss';
import './login.scss';
import { apiIspLoginAction } from '../../../slices/api-isp/api-isp-login-slice';
import { useDidMount } from '../../../hooks/life-cycle';
import { apiCompany, apiIsp } from '../../../managers/api-manager';
import { dialogAction } from '../../../slices/dialog-slice';
import { apiCompanyLoginAction } from '../../../slices/api-company/api-company-login-slice';
import { Link } from 'react-router-dom';
import { CheckAllValid } from '../../../managers/validation/validation';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { APP_NAME } from '../../../models/foundation';
import { debugIsLogin, toggleDebugIsLogin } from '../../../utilities/debug/is-login.debug';
import { loginCompanyUserInfoAction } from '../../../slices/login-company-user-info-slicer';

const validations = {
  requireId: ValidationFactory('require'),
  requirePass: ValidationFactory('require'),
};

export const Login = (props: PageComponentDefaultProps) => {
  // - dispatch -
  const dispatch = useDispatch();
  // - props -
  const { apiManger } = props;
  // - state -
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  // const [remember, setRemember] = useState(false);
  const [checkedLogin, setCheckedLogin] = useState(false);
  // - callback -
  const handleChangeId = useCallback((v) => {
    setId(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);
  const handleChangePassword = useCallback((v) => {
    setPassword(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);
  // const handleClickRemember = useCallback(
  //   (v) => {
  //     setRemember(v)
  //   },
  //   [],
  // );

  // バリデーションのトータルチェック
  // const isDisabled = CheckAllValid(validations);
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  
  const handleClickSubmit = useCallback(() => {
    setIsDisabled(true)
    if (apiManger.type === 'isp') {
      toggleDebugIsLogin(true);
      apiManger
        .isp((api) => {
          dispatch(apiIspLoginAction.login({
            param: {
              email: id,
              password,
            },
            callback: {
              success: () => {
                dispatch(push(RoutingPath.ispPortal));
              },
              failed: () => setIsDisabled(false),
            },
          }));
        });
    } else {
      toggleDebugIsLogin(true, '2');
      apiManger
        .company((api) => {
          dispatch(apiCompanyLoginAction.login({
            param: {
              email: id,
              password,
              isKeep: true,
            },
            callback: {
              success: () => {
                dispatch(push(RoutingPath.companyStatus));
              },
              failed: () => setIsDisabled(false)
            },
          }));
        });
    }
  }, [id, password]);
  // - did mount -
  useDidMount(() => {
    setCheckedLogin(false);
    if (apiManger.type === 'isp') {
      if (debugIsLogin() !== '1') {
        setCheckedLogin(true);
        return;
      };
      apiIsp.islogin().get()
        .then((v) => {
          if (v.header.status_code === 200) {
            // リダイレクト
            dispatch(push(RoutingPath.ispPortal));
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setCheckedLogin(true);
        });
    } else {
      if (debugIsLogin() !== '2') {
        setCheckedLogin(true);
        return;
      };
      apiCompany.islogin().get()
        .then((v) => {
          if (v.header.status_code === 200) {
            // リダイレクト
            dispatch(push(RoutingPath.companyStatus));
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setCheckedLogin(true);
        });
    }
  });
  useEffect(() => {
    setIsDisabled(CheckAllValid(validations))
  }, [id, password]);

  return !checkedLogin ? (
    <></>
  ) : (
    <>
      <div
        id="login"
        className="main_cnt company"
      >
        <div className="wrapper">
          <h1 style={{position: 'relative'}}>
            <img
              style={{ width: '430px', marginBottom: '40px' }}
              src={logo}
              alt={`${APP_NAME}管理サイト`}
            />
            <img
              style={{ position: 'absolute', top: '160px', left: '128px' }}
              src={logo_kanri}
              alt={`${APP_NAME}管理サイト`}
            />
          </h1>
          {/*<p>ご利用の方はログインして下さい</p>*/}
          <div className="input_wrap">
            <Input
              value={id}
              onChange={(e) => handleChangeId(e.target.value)}
              placeholder="ユーザーID"
              validations={[
                validations.requireId,
              ]}
            />
          </div>
          <div className="input_wrap">
            <Input
              type="password"
              value={password}
              onChange={(e) => handleChangePassword(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                  handleClickSubmit();
                }
              }}
              placeholder="パスワード"
              validations={[
                validations.requirePass,
              ]}
            />
          </div>
          <Button
              label="ログイン"
              onClick={handleClickSubmit}
              disabled={isDisabled}
            />
            <br />
            <Link
              style={{ textDecoration: 'none' }}
              to={apiManger.type === 'company'
                  ? RoutingPath.companyPasswordReissue
                  : RoutingPath.ispPasswordReissue}
            >パスワードを忘れた方はコチラ</Link>
          {/* {apiManger.type === 'company' ? (<> */}
            {/*<div className="align_left checkbox_wrap">*/}
            {/*  <Input*/}
            {/*    type="checkbox"*/}
            {/*    id="remember"*/}
            {/*    className="checkbox_style"*/}
            {/*    onChange={() => handleClickRemember(!remember)}*/}
            {/*  />*/}
            {/*  <label htmlFor="remember">ログイン状態を保持する</label>*/}
            {/*</div>*/}
            {/* <Button
              label="ログイン"
              onClick={handleClickSubmit}
              disabled={isDisabled}
            />
            <br />
            <Link
              style={{ textDecoration: 'none' }}
              to={RoutingPath.companyPasswordReissue}
            >パスワードを忘れた方はコチラ</Link>
          </>) : (
            <Button
              label="ログイン"
              onClick={handleClickSubmit}
              disabled={isDisabled}
            />

          )} */}
        </div>
      </div>
    </>
  );
};
