import { PageComponentDefaultProps } from "../../models/page-component-default-props";
import { InfoParams } from "../../slices/information-slice";
import { Button } from "../ui/button/button";
import { InformationEditor } from "../pages/information/information-editor";
import { InfomationDetailEntity, NotificationDetaEntity } from '../pages/information/type/infomation.type';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { dialogAction } from "../../slices/dialog-slice";
import { useCallback, useState, useMemo, useEffect } from 'react';
import { apiIsp, ApiManager, apiCompany } from '../../managers/api-manager';
import { DateFormatter } from '../../utilities';
import { createPlaneText } from "../../utilities/create-plane-text";
import { OutputData } from "@editorjs/editorjs";
import { useDidMount } from '../../hooks/life-cycle';

type Props ={
  isDisabled: boolean;
  checkValid: (e: boolean) => void;
  id: string;
  initParam: InfomationDetailEntity | null;
  callback?: () => void;
} & PageComponentDefaultProps;

export const InforMationEditDialog = (props: Props) => {
  const { id, isDisabled, checkValid, callback, initParam, apiManger } = props;
  const dispatch = useAppDispatch();
  // -- state --
  const [disabled, setDisabled] = useState(false);
  const [info, setInfo] = useState<InfoParams | null>(null);
  const baseApi = useMemo(() => apiManger.type === 'company' ? apiCompany : apiIsp, [apiManger]);
  // -- callback --
  const onClickUpdate = useCallback(() => {
    if (!info) return;
    const body = JSON.stringify(info.body)
    baseApi.informations(id).post({
      title: info.title,
      body: info.isUrl ? undefined : body,
      body_text: info.isUrl ? undefined : createPlaneText(info.body as OutputData),
      category: info.category,
      is_url: info.isUrl || undefined,
      url: info.isUrl ? info.url || undefined : undefined,
      level: info.level ? 2 : 1,
      notification_end_date: info.notificationEndDate ? DateFormatter.date2str(info?.notificationEndDate, 'YYYYMMDD', '-') : '',
      notification_start_date: info.notificationStartDate ? DateFormatter.date2str(info?.notificationStartDate, 'YYYYMMDD', '-') : '',
      sender_name: info.senderName ?? '',
      sender_list: (initParam?.sender_list ?? []) as any,
    }).then(() => {
      dispatch(dialogAction.pushMessage({
        title: '完了',
        message: [
          `更新完了しました。`,
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              props.callback?.();
              dispatch(dialogAction.popAll());
            },
            color:'primary',
          }
        ]
      }))
    }).catch((e) => ApiManager.errorFunc(e))
  }, [info, initParam]);

  return (
    <>
      <InformationEditor
        mode={'edit'}
        apiManger={apiManger}
        isDisabled={isDisabled}
        // checkValid={(bool) => {
          //   onChangeDisabled(bool);
        // }}
        checkValid={(v) => {
          // console.log(v);
          // checkValid(v)
          setDisabled(!v);
        }}
        // initParam={detailData ?? undefined}
        initParam={initParam ?? undefined}
        callback={(info) => {
          setInfo(info)
        }}
        />
      <div className="info_edit_dialog__actions">
        <Button
          label="キャンセル"
          color="tertiary"
          onClick={() => dispatch(dialogAction.pop())}
          />
        <Button
          label="更新"
          disabled={disabled}
          onClick={() => {
            onClickUpdate();
          }}
          />
      </div>
    </>
  )
}