import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { SideBar } from '../../ui/sidebar/sidebar';
import { Header } from '../../ui/header/header';
import { apiCompany, apiIsp, ApiManager } from '../../../managers/api-manager';
import { dialogAction } from '../../../slices/dialog-slice';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import { useDidMount } from '../../../hooks/life-cycle';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';

const validations: { [key: string]: Validation } = {
  requirePassword: ValidationFactory('require'),
  requireNewPassword: ValidationFactory('require'),
  requirePasswordConfirm: ValidationFactory('require'),
  checkPasswordConfirm: ValidationFactory('default'),
  lengthOver8: ValidationFactory('lengthOver8'),
  lengthOver8New: ValidationFactory('lengthOver8'),
  lengthOver8Confirm: ValidationFactory('lengthOver8'),
};

const companyBreadcrumbList = [
  breadcrumbParts.company.home,
  breadcrumbParts.company.editPassword,
];

const EditPassword = (props: PageComponentDefaultProps) => {
  const  { apiManger } = props;
  const dispatch = useDispatch();

  // パスワード
  const [password, setPassword] = useState('');
  const handleChangePassword = useCallback((v) => {
    setPassword(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);
  // 確認用新パスワード
  const [newPassword, setNewPassword] = useState('');
  const handleChangeNewPassword = useCallback((v) => {
    setNewPassword(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);
  // 確認用パスワード
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const handleChangeNewPasswordConfirm = useCallback((v) => {
    setNewPasswordConfirm(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);

  // バリデーションのトータルチェック
  // const isDisabled = CheckAllValid(validations)
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  const [validFlag, setValidFlag] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);

  const handleClickCancel = useCallback(() => {
    const path = apiManger.type === 'company'
     ? RoutingPath.companyStatus
     : RoutingPath.ispPortal;
    dispatch(push(path));
  }, []);
  const handleClickEdit = useCallback(() => {
    const api = apiManger.type === 'company'
     ? apiCompany
     : apiIsp;
     const path = apiManger.type === 'company'
     ? RoutingPath.apiCompanyLogin
     : RoutingPath.apiIspLogin;

    setIsDisabled(true)
    dispatch(dialogAction.pushMessage({
      title: '確認',
      message: ['パスワードを変更してもよろしいですか'],
      buttons: [{
        label: 'キャンセル',
        callback: () => {
          dispatch(dialogAction.pop());
          setIsDisabled(false)
        },
      },
        {
          label: 'OK',
          callback: () => {
            dispatch(dialogAction.pop());
            api.profiles().password().post({
              password: password,
              new_password: newPassword,
            }).then(() => {
              dispatch(dialogAction.pushMessage({
                title: '確認',
                message: ['パスワードを変更しました', '再度ログインしてください'],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogAction.pop());
                    dispatch(push(path));
                  },
                }],
              }));
            }).catch((e) => {
              ApiManager.errorFunc(e);
              setIsDisabled(false)
            });
          },
        }],
    }));
  }, [password, newPassword]);

  /**　パスワードのバリデーション監視 */
  const checkValidPasswordInput = useCallback(() => {
    if (!passwordRef.current ||
      !passwordConfirmRef.current ||
    passwordConfirmRef.current.value.length <= 0) {
      return;
    }
    passwordConfirmRef.current.focus();
    passwordConfirmRef.current.blur();
    passwordRef.current.focus();
  }, [password]);
  useEffect(() => {
    if (validFlag) {
      checkValidPasswordInput();
    }
  }, [newPassword]);
  // トータルバリデーションチェック
  useEffect(() => {
    setIsDisabled(CheckAllValid(validations));
  }, [
    password,
    newPassword,
    newPasswordConfirm,
    validFlag,
  ]);

  /** バリデーション更新 */
  useDidMount(() => {
    validations['lengthOver8'] = ValidationFactory('lengthOver8');
    validations['lengthOver8New'] = ValidationFactory('lengthOver8');
    validations['lengthOver8Confirm'] = ValidationFactory('lengthOver8');
    validations['requirePassword'] = ValidationFactory('require');
    validations['requireNewPassword'] = ValidationFactory('require');
    validations['requirePasswordConfirm'] = ValidationFactory('require');
    validations['checkPasswordConfirm'] = new Validation({
      test: (v: string) => v === passwordRef.current?.value,
      errorMessages: ['パスワードが一致しません'],
    });
    setValidFlag(true);
  });

  return (
    <div
      id="App"
      className="setting"
    >
      <SideBar
        currentPage=""
        apiManger={apiManger.type}
      />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          {/*<BreadcrumbList breadcrumbList={companyBreadcrumbList} />*/}
          <section className="">
            <header>
              <h2>パスワード変更</h2>
              <div>ご希望のパスワードを入力してください</div>
            </header>
            <div className="edit_box">
              <div className="item_wrap">
                <div className="item_head">
                  現在のパスワード
                  <span
                    className="required"
                    style={{ marginLeft: '5px' }}
                  >必須</span>
                </div>
                <div className="item_cnt">
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => handleChangePassword(e.target.value)}
                    validations={[
                      validations.requirePassword,
                      validations.lengthOver8,
                    ]}
                  />
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_head">
                  新しいパスワード
                  <span
                    className="required"
                    style={{ marginLeft: '5px' }}
                  >必須</span>
                </div>
                <div className="item_cnt">
                  <Input
                    type="password"
                    value={newPassword}
                    onChange={(e) => handleChangeNewPassword(e.target.value)}
                    validations={[
                      validations.requireNewPassword,
                      validations.lengthOver8New,
                    ]}
                    ref={passwordRef}
                  />
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_head">
                  新しいパスワード(確認)
                  <span
                    className="required"
                    style={{ marginLeft: '5px' }}
                  >必須</span>
                </div>
                <div className="item_cnt">
                  <Input
                    type="password"
                    value={newPasswordConfirm}
                    onChange={(e) => handleChangeNewPasswordConfirm(e.target.value)}
                    validations={[
                      validations.requirePasswordConfirm,
                      validations.lengthOver8Confirm,
                      validations.checkPasswordConfirm,
                    ]}
                    ref={passwordConfirmRef}
                  />
                </div>
              </div>
            </div>
            <div className="btn_box align_center">
              <Button
                size="large"
                color="tertiary"
                label="キャンセル"
                onClick={handleClickCancel}
              />
              <Button
                size="large"
                label="変更する"
                onClick={handleClickEdit}
                disabled={isDisabled}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditPassword;
