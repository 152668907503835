import API, { Mode } from "../api/api";

type Param = {
  mode: Mode,
  host?: string
}
// - apiの向き先調整 -
const apiParam: Param = window.location.hostname === 'localhost'
  ? { mode: "local" }
  : { mode: "develop", host: "." }

const api = new API({mode: apiParam.mode, host: apiParam.host} );
api.on("response-error", (e) => {
  console.log("response error : ", e);
  throw e;
});
api.on("network-error", (e) => {
  console.log("network error : ", e);
});
api.on("finish", (e) => {});
export const apiIsp = api.ispWeb();
export const apiCompany = api.companyWeb();
export const apiData = api.dataApp();

export class ApiManager {
  constructor(public type: "isp" | "company") {}

  static connectStart(id: string): string {
    // TODO ローディング状態実装
    return id;
  }

  static connectEnd(id: string) {}

  static onError = (e: any) => {};

  static errorFunc(e: any) {
    this.onError(e);
  }

  isp(callback: (api: typeof apiIsp) => void): ApiManager {
    if (this.type === "isp") {
      callback(apiIsp);
    }
    return this;
  }

  company(callback: (api: typeof apiCompany) => void): ApiManager {
    if (this.type === "company") {
      callback(apiCompany);
    }
    return this;
  }
}
