import { TreeView } from '../../models/tree-view';
import { Button } from '../ui/button/button';
import { Organization } from '../../models/edit-tree-view/models';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { dialogAction } from '../../slices/dialog-slice';
import { Leaf } from '../../models/tree-view/models';
import { useDidMount } from '../../hooks/life-cycle';
import { useAppSelector } from '../../app/hooks';
import { IspLogOperation } from '../../models/logs/isp-log-operation';
import { CompanyLogOperation } from '../../models/logs/company-log-operation';
import { isEqual } from 'lodash';

type OrganizationSelectDialogInformationProps = {
  callback: (v: { id: string, label: string }[]) => void,
  orgData: Organization[],
  selectList?: { id: string, label: string }[]
};

export const OrganizationSelectDialogInformation = (props: OrganizationSelectDialogInformationProps) => {
  const dispatch = useDispatch();
  const { type } = useAppSelector((state) => state.serverEnv);
  const { callback, orgData, selectList } = props;
  const [data, setData] = useState<Organization[]>([]);
  // 現在、選択されているリーフ
  const leafArr: Leaf[] = [];
  const [leafList, setLeafList] = useState<Leaf[]>([]);
  // - 使用済みLeafのチェック処理 -
  const checkLeaf = useCallback((l: Leaf, isChecked: boolean, nonCheckLeafs: Leaf[]) => {
    if (isChecked) {
      leafArr.length = 0;
      leafArr.push(...nonCheckLeafs);
    } else {
      leafArr.push(l);
    }
  }, [leafArr]);
  // - Leaf押下 -
  const handlerChangeValue = useCallback((v: Leaf) => {
    // type === 'isp'
    //   ? IspLogOperation(mode === 'add' ? 'companyUserAddOrganizationList' : 'companyUserEditOrganizationList')
    //   : CompanyLogOperation(mode === 'add' ? 'companyUserAddOrganizationList' : 'companyUserEditOrganizationList');
    // -- check用変数 --
    const isChecked = leafArr.includes(v);
    const nonCheckLeafs = leafArr.filter((val) => val !== v);
    // -- check関数 --
    checkLeaf(v, isChecked, nonCheckLeafs);
    // -- stateの更新 --
    setLeafList(([...prev]) => {
      v.isActive = !isChecked;
      if (isChecked) {
        return [...nonCheckLeafs];
      } else {
        if (prev.findIndex((v2) => isEqual(v2, v)) === -1) {
          return [...prev, v];
        } else {
          return [...prev];
        }
      }
    });
  }, [leafArr, leafList]);

  const handlerClickCancel = useCallback(() => {
    dispatch(dialogAction.pop());
  }, []);

  const handlerClickOk = useCallback(() => {
    if (leafList) {
      callback(leafList);
    }
    dispatch(dialogAction.pop());
  }, [leafList]);

  useDidMount(() => {
    const _orgData = orgData.filter((v: any) => {
      return v.is_valid === true;
    });
    setData(_orgData as any);
  });

  return (
    <div className="organization_tree">
      <div className="tree_wrap">
        <TreeView
          organizations={data}
          selectOrg={''}
          onClick={handlerChangeValue}
          selectList={selectList}
        />
      </div>
      {/* TODO 6以降 */}
      {/*<div className="map">/!*★TODO*!/*!/*/}
      {/*  <div className="frame" style={{ width: "250px", height: "250px" }}>*/}
      {/*    <div className="line" style={{ width: "100px", height: "100px", top: "10px", left: "10px" }}>*/}

      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="btn_box">*/}
      {/*    <button className="on">*/}
      {/*      <img src={rangeOff} alt="" />*/}
      {/*      <img src={rangeOn} alt="" className="on" />*/}
      {/*    </button>*/}
      {/*    <button className="">*/}
      {/*      <img src={zoomInOff} alt="" />*/}
      {/*      <img src={zoomInOn} alt="" className="on" />*/}
      {/*    </button>*/}
      {/*    <button className="">*/}
      {/*      <img src={zoomOutOff} alt="" />*/}
      {/*      <img src={zoomOutOn} alt="" className="on" />*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="btn_box">
        <Button
          size="medium"
          color="tertiary"
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <Button
          size="medium"
          label="OK"
          onClick={handlerClickOk}
        />
      </div>
    </div>
  );
};
