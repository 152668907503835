import {createSlice} from '@reduxjs/toolkit';
import {apiIsp} from '../../managers/api-manager';

export type IspLogoutSlicerState = {};

const initialState: IspLogoutSlicerState = {};

const logout = apiIsp.logout();

export const apiIspLogoutSlice = createSlice({
  name: 'api-isp-logout',
  initialState,
  reducers: {},
});

export const apiIspLogoutAction = apiIspLogoutSlice.actions;
export const apiIspLogoutReducer = apiIspLogoutSlice.reducer;
