import {
  configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { createHashHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { dialogReducer } from '../slices/dialog-slice';
import { portalReducer } from '../slices/portal-slice';
import { apiIspUsersReducer } from '../slices/api-isp/api-Isp-users-slice';
import { companyReducer } from '../slices/company-slice';
import { companyUserReducer } from '../slices/company-user-slice';
import { apiIspLoginReducer } from '../slices/api-isp/api-isp-login-slice';
import { apiIspCommonReducer } from '../slices/api-isp/api-isp-common-slicer';
import { apiIspIsloginReducer } from '../slices/api-isp/api-isp-islogin-slice';
import { apiIspLogoutReducer } from '../slices/api-isp/api-isp-logout-slice';
import { apiIspCompaniesReducer } from '../slices/api-isp/api-isp-companies-slice';
import { ispUserReducer } from '../slices/isp-user-slice';
import {loginIspUserInfoReducer} from '../slices/login-user-info-slicer';
import {commonDataReducer} from '../slices/common-data-slice';
// import logger from 'redux-logger';
import {apiIspCompanyUsersReducer} from '../slices/api-isp/api-isp-company-users-slice';
import { apiIspOrganizationsReducer } from '../slices/api-isp/api-isp-organization-slice';
import { envReducer } from '../slices/env-slice';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { apiCompanyLoginReducer } from '../slices/api-company/api-company-login-slice';
import { loginCompanyUserInfoReducer } from '../slices/login-company-user-info-slicer';
import { apiCompanyOrganizationsReducer } from '../slices/api-company/api-company-organization-slice';
import { apiCompanyCommonReducer } from '../slices/api-company/api-company-common-slicer';
import { apiCompanyUsersReducer } from '../slices/api-company/api-company-users-slice';
import { serverEnvReducer } from '../slices/server-env-slice';
import { persistStore } from 'redux-persist';
import { informationReducer } from '../slices/information-slice';

export const history = createHashHistory();

const reducer = combineReducers({
  // route
  router: connectRouter(history),
  // component
  dialog: dialogReducer,
  loginIspUserInfo: loginIspUserInfoReducer,
  loginCompanyUserInfo: loginCompanyUserInfoReducer,
  portal: portalReducer,
  company: companyReducer,
  companyUser: companyUserReducer,
  ispUser: ispUserReducer,
  commonData: commonDataReducer,
  env: envReducer,
  serverEnv: serverEnvReducer,
  information: informationReducer,
  // api-isp
  apiIspCommon: apiIspCommonReducer,
  apiIspCompanies: apiIspCompaniesReducer,
  apiIspCompanyUsers: apiIspCompanyUsersReducer,
  apiIspIslogin: apiIspIsloginReducer,
  apiIspLogin: apiIspLoginReducer,
  apiIspLogout: apiIspLogoutReducer,
  apiIspUsers: apiIspUsersReducer,
  apiIspOrganizations: apiIspOrganizationsReducer,
  // api-company
  apiCompanyLogin: apiCompanyLoginReducer,
  apiCompanyOrganizations: apiCompanyOrganizationsReducer,
  apiCompanyCommon: apiCompanyCommonReducer,
  apiCompanyUsers: apiCompanyUsersReducer,

});

const persistConfig = {
key: 'root',
whitelist: ['loginIspUserInfo', 'loginCompanyUserInfo', 'serverEnv'],
storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const customizeMiddleWare = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware() {
    return customizeMiddleWare
      .concat(routerMiddleware(history))
      // .concat(logger)
      ;
  },
});

export　const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;
