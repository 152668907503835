import { Switch, Route, Redirect } from "react-router-dom";
import { Login } from "../components/pages/login/login";
import { RoutingPath } from "./routing-path";
import { Portal } from "../components/pages/portal/portal";
import { CompanyList } from '../components/pages/company/company-list';
import { IspUserList } from '../components/pages/user/isp-user-list';
import { CompanyDetail, CompanyDetailProps } from '../components/pages/company/company-detail';
import { OrgManagement } from '../components/pages/organization/org-management';
import { CompanyUserList } from '../components/pages/company/company-user-list';
import { CompanyInvitationUser } from '../components/pages/company/company-invitation-user';
import { CompanyEditUser } from '../components/pages/company/company-user-edit';
import { CompanyAdd, CompanyEdit } from '../components/pages/company/company-add';
import { IspUserEdit, IspUserEditProps } from '../components/pages/user/isp-user-edit';
import React, { PropsWithChildren } from 'react';
import { RouteComponentProps } from 'react-router/ts4.0';
import { ApiManager } from '../managers/api-manager';
import { CompanyStatus } from '../components/pages/company/company-status';
import { CompanyUserAdd } from '../components/pages/company/company-user-add';
import { IspUserAdd } from '../components/pages/user/isp-user-add';
import { IspUserAddCheck, UserAddCheckProps } from '../components/pages/user/isp-user-add-check';
import { CompanyUserAddResult, CompanyUserAddResultProps } from '../components/pages/company/company-user-add-result';
import ErrorToken from '../components/pages/error/error-token';
import PasswordReissue from '../components/pages/login/password-reissue';
import PasswordReset from '../components/pages/login/password-reset';
import UserSetting from '../components/pages/profiles/user-setting'
import EditPassword from '../components/pages/profiles/edit-password'
import PasswordResetResult from '../components/pages/login/password-reset-result';
import { Sample } from '../components/pages/sample/sample';
import { InformationList } from "../components/pages/information/information-list";
import { InformationDetail } from "../components/pages/information/information-detail";
import { OperationLog } from "../components/pages/log/operation-log";
import { InformationAdd } from "../components/pages/information/information-add";
import { NotificationSettings } from "../components/pages/information/notification-settings";
import { NotificationSettingsCompany } from "../components/pages/information/notification-settings-company";
import { Articles } from '../components/pages/articles/articles';

interface IRouteProps<T> extends PropsWithChildren<RouteComponentProps<T>> {
}

function routeProps<T>(props: IRouteProps<T>): T {
  return props.location.state as T;
}

const apiManagerIsp = new ApiManager('isp');
const apiManagerCompany = new ApiManager('company');

export const Routes = (
  <Switch>
    /** ISP管理 */
    <Route exact path={RoutingPath.apiIspLogin} component={() => <Login apiManger={apiManagerIsp} />} />
    <Route exact path={RoutingPath.ispPortal} component={() => <Portal apiManger={apiManagerIsp} />} />
    <Route exact path={RoutingPath.ispCompanyList} component={() => <CompanyList apiManger={apiManagerIsp} />} />
    <Route exact path={RoutingPath.ispEditPassword} component={() => <EditPassword apiManger={apiManagerIsp} />} />
    <Route exact path={RoutingPath.ispPasswordReissue} component={() => <PasswordReissue apiManger={apiManagerIsp} />} />
    <Route exact path={RoutingPath.ispPasswordReset} component={() => <PasswordReset apiManger={apiManagerIsp} />} />
    <Route exact path={RoutingPath.ispPasswordResetResult} component={() => <PasswordResetResult apiManger={apiManagerIsp} />} />
    <Route
      exact
      path={RoutingPath.ispOrgManagement}
      component={(props: any) => (
        <OrgManagement
          companyCode={props.match.params?.companyCode}
          apiManger={apiManagerIsp}
        />
      )}
    />
    <Route exact path={RoutingPath.ispUserList} component={() => <IspUserList apiManger={apiManagerIsp} />} />
    <Route exact path={RoutingPath.ispInvitationUser} component={() => <IspUserEdit apiManger={apiManagerIsp} />} />
    <Route exact path={RoutingPath.ispUserAdd} component={() => <IspUserAdd apiManger={apiManagerIsp} />} />
    <Route
      exact
      path={RoutingPath.ispUserAddCheck}
      component={(props: IRouteProps<UserAddCheckProps>) => (
        <IspUserAddCheck
          apiManger={apiManagerIsp}
          isCompanyUser={false}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.ispUserEdit}
      component={(props: IRouteProps<IspUserEditProps>) => (
        <IspUserEdit
          apiManger={apiManagerIsp}
          userId={props.match.params?.userId}
        />
      )}
    />
    <Route exact path={RoutingPath.ispCompanyAdd} component={() => <CompanyAdd apiManger={apiManagerIsp} />} />
    <Route
      exact
      path={RoutingPath.ispCompanyDetail}
      component={(props: IRouteProps<CompanyDetailProps>) => (
        <CompanyDetail
          apiManger={apiManagerIsp}
          companyCode={props.match.params?.companyCode}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.ispCompanyEdit}
      component={(props: IRouteProps<CompanyDetailProps>) => (
        <CompanyEdit
          apiManger={apiManagerIsp}
          companyCode={props.match.params?.companyCode}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.ispCompanyUserList}
      component={(props: any) => (
        <CompanyUserList
          companyCode={props.match.params?.companyCode}
          apiManger={apiManagerIsp}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.ispCompanyInvitationUser}
      component={(props: any) => (
        <CompanyInvitationUser
          companyCode={props.match.params?.companyCode}
          apiManger={apiManagerIsp}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.ispCompanyUserAdd}
      component={(props: any) => (
        <CompanyUserAdd
          companyCode={props.match.params?.companyCode}
          apiManger={apiManagerIsp}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.ispCompanyUserAddCheck}
      component={(props: IRouteProps<UserAddCheckProps>) => (
        <IspUserAddCheck
          companyCode={props.match.params?.companyCode}
          apiManger={apiManagerIsp}
          isCompanyUser={true}
        />
      )} />
    <Route
      exact
      path={RoutingPath.ispCompanyUserAddResult}
      component={(props: IRouteProps<CompanyUserAddResultProps>) => (
        <CompanyUserAddResult
          companyCode={props.match.params?.companyCode}
          apiManger={apiManagerIsp} />)} />
    <Route
      exact
      path={RoutingPath.ispCompanyUserEdit}
      component={(props: any) => (
        <CompanyEditUser
          memberId={props.match.params?.memberId}
          companyCode={props.match.params?.companyCode}
          apiManger={apiManagerIsp}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.ispCompanyStatus}
      component={(props: any) => (
        <CompanyStatus
          companyCode={props.match.params?.companyCode}
          apiManger={apiManagerIsp}
        />
      )}
      />
    <Route exact path={RoutingPath.ispErrorToken} component={() => <ErrorToken />} />
    /** ログ管理 */
    <Route exact path={RoutingPath.ispLogExport} component={() => <OperationLog apiManger={apiManagerIsp} />} />
    /** インフォメーション管理 */
    <Route exact path={RoutingPath.ispInfo} component={() => <InformationList apiManger={apiManagerIsp} />} />
    <Route exact path={RoutingPath.ispInfoAdd} component={() => <InformationAdd apiManger={apiManagerIsp} mode={'add'} />} />
    <Route exact path={RoutingPath.ispInfoClone} component={() => <InformationAdd apiManger={apiManagerIsp} mode={'clone'} />} />
    <Route exact path={RoutingPath.ispInfoDetail} component={() => <InformationDetail apiManger={apiManagerIsp} />} />
    <Route exact path={RoutingPath.ispInfoSelect} component={() => <NotificationSettings apiManger={apiManagerIsp} />} />

    /** 企業管理 */
    <Route exact path={RoutingPath.apiCompanyLogin} component={() => <Login apiManger={apiManagerCompany} />} />
    <Route exact path={RoutingPath.companyPasswordReissue} component={() => <PasswordReissue apiManger={apiManagerCompany} />} />
    <Route exact path={RoutingPath.companyPasswordReset} component={() => <PasswordReset apiManger={apiManagerCompany} />} />
    <Route exact path={RoutingPath.companyPasswordResetResult} component={() => <PasswordResetResult apiManger={apiManagerCompany} />} />
    <Route
      exact
      path={RoutingPath.companyOrgManagement}
      component={(props: any) => (
        <OrgManagement
          apiManger={apiManagerCompany}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.companyUserList}
      component={(props: any) => (
        <CompanyUserList
          apiManger={apiManagerCompany}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.companyInvitationUser}
      component={(props: any) => (
        <CompanyInvitationUser
          apiManger={apiManagerCompany}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.companyUserAdd}
      component={(props: any) => (
        <CompanyUserAdd
          apiManger={apiManagerCompany}
        />
      )}
    />
    <Route
      exact
      path={RoutingPath.companyUserAddCheck}
      component={(props: IRouteProps<UserAddCheckProps>) => (
        <IspUserAddCheck
          apiManger={apiManagerCompany}
          isCompanyUser={true}
        />
      )} />
    <Route
      exact
      path={RoutingPath.companyUserAddResult}
      component={(props: IRouteProps<CompanyUserAddResultProps>) => (
        <CompanyUserAddResult
          apiManger={apiManagerCompany} />)} />
    <Route
      exact
      path={RoutingPath.companyUserEdit}
      component={(props: any) => (
        <CompanyEditUser
          memberId={props.match.params?.memberId}
          apiManger={apiManagerCompany}
        />
      )}
    />
     <Route
      exact
      path={RoutingPath.companyStatus}
      component={(props: any) => (
        <CompanyStatus
          apiManger={apiManagerCompany}
        />
      )}
    />
    <Route exact path={RoutingPath.companyErrorToken} component={() => <ErrorToken />} />
    <Route exact path={RoutingPath.companyUserSetting} component={() => <UserSetting />} />
    <Route exact path={RoutingPath.companyEditPassword} component={() => <EditPassword apiManger={apiManagerCompany} />} />

    /** ログ管理 */
    <Route exact path={RoutingPath.companyLogExport} component={() => <OperationLog apiManger={apiManagerCompany} />} />
    /** インフォメーション管理 */
    <Route exact path={RoutingPath.companyInfo} component={() => <InformationList apiManger={apiManagerCompany} />} />
    <Route exact path={RoutingPath.companyInfoAdd} component={() => <InformationAdd apiManger={apiManagerCompany} mode={'add'} />} />
    <Route exact path={RoutingPath.companyInfoClone} component={() => <InformationAdd apiManger={apiManagerCompany} mode={'clone'} />} />
    <Route exact path={RoutingPath.companyInfoDetail} component={() => <InformationDetail apiManger={apiManagerCompany} />} />
    <Route exact path={RoutingPath.companyInfoSelect} component={() =><NotificationSettingsCompany apiManger={apiManagerCompany} />} />
    /** 物件一覧 */
    <Route exact path={RoutingPath.companyArticles} component={() =><Articles apiManger={apiManagerCompany} />} />

    <Route  path={'/admin'} component={() => <Login apiManger={apiManagerIsp} />} />
    <Route  path={'/company'} component={() => <Login apiManger={apiManagerCompany} />} />

    <Redirect to={
      window.location.hostname.match('company') ?
        RoutingPath.apiCompanyLogin :
        RoutingPath.apiIspLogin
    } />

  </Switch>
);
