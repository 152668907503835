import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from '../input/input';
import { Button } from '../button/button';
import { useDispatch } from 'react-redux';
import { dialogAction } from '../../../slices/dialog-slice';
import { Select } from '../select/select';
import { prefectureList } from '../../../models/prefecture-list';
import { apiCompany, apiIsp, ApiManager } from '../../../managers/api-manager';
import { Organization } from '../../../models/tree-view/models/organization';
import { useDidMount } from '../../../hooks/life-cycle';
import { apiIspOrganizationsAction } from '../../../slices/api-isp/api-isp-organization-slice';
import { apiCompanyOrganizationsAction } from '../../../slices/api-company/api-company-organization-slice';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { Checkbox } from '../checkbox/checkbox';
import { OrganizationSelectDialog } from '../../dialog/organization-select-dialog';
import { ApiGetOrganizationsResponse } from '../../../api/api/isp-web/companies/organizations/api-get-organizations.response';
import { ApiGetOrganizationDetailResponse } from '../../../api/api/company-web/organizations/api-get-organization-detail.response';
import { ApiGetAddressResponse } from '../../../api/api/isp-web/companies/organizations/address/api-get-address.response';

const findPrefecture = (prefectureKey: number) => {
  const foundIndex = prefectureList.findIndex((v) => Number(v.key) === prefectureKey);
  return foundIndex > -1 ? (
    prefectureList[foundIndex].key
  ) : (
    '13'
  );
};

type IOrgState = {
  name: string;
  postalCode: string;
  addressTop: number;
  addressMiddle: string;
  addressLast: string;
  organizationCode: string,
  hierarchy: number,
};

type ModalProps = {
  companyCode: string,
  orgState: IOrgState,
  setUpdateFlag: React.Dispatch<React.SetStateAction<boolean>>,
  type: 'add' | 'edit',
  apiManger: 'isp' | 'company',
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>,
};

// バリデーション用意
const validations: { [key: string]: Validation } = {
  require: ValidationFactory('requireEdit'),
  length30: ValidationFactory('length30'),
  length50: ValidationFactory('length50'),
  postCode: ValidationFactory('postCode'),
};

export const OrganizationEditModal = (props: ModalProps) => {
  const { companyCode, orgState, setUpdateFlag, type, apiManger, setIsDisabled } = props;
  const dispatch = useDispatch();
  const [orgId, setOrgId] = useState('');
  const [name, setName] = useState('');
  const [isNotAddressCopy, setIsNotAddressCopy] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [addressTop, setAddressTop] = useState(13);
  const [addressMiddle, setAddressMiddle] = useState('');
  const [addressLast, setAddressLast] = useState('');
  const [parentOrganizationCode, setParentOrganizationCode] = useState('');
  const [parentHierarchy, setParentHierarchy] = useState(0);
  const [validFlag, setValidFlag] = useState(false);
  const [resolveFlag, setResolveFlag] = useState(false);
  const [isMargeOrg, setIsMargeOrg] = useState(false);
  const [margeOrg, setMargeOrg] = useState<{id: string, label: string}>({id: '', label: ''});

  const handleChangeName = useCallback((v) => {
    setName(v);
  }, []);
  const handleChangeIsNotAddressCopy = useCallback((v) => {
    setIsNotAddressCopy(v);
  }, []);
  const handleChangePostalCode = useCallback((v) => {
    const code: string = v.replace(/[^0-9\-]+$/, '');
    setPostalCode(code);
  }, []);
  const handleChangeAddressTop = useCallback((v) => {
    setAddressTop(v);
  }, []);
  const handleChangeAddressMiddle = useCallback((v) => {
    setAddressMiddle(v);
  }, []);
  const handleChangeAddressLast = useCallback((v) => {
    setAddressLast(v);
  }, []);
  const handleChangeIsMargeOrg = useCallback((v) => {
    setIsMargeOrg(v);
  }, []);
  const handleClickMargeOrg = useCallback((v) => {
    if (apiManger === 'isp') {
      IspLogOperation('organizationMargeSelectOrgClick', () => {
        apiIsp.companies(companyCode).organizations().get()
          .then((res) => {
            const orgList = (res as ApiGetOrganizationsResponse).body.data;
            dispatch(dialogAction.push({
              title: '組織指定',
              element: <OrganizationSelectDialog
                orgData={orgList as any}
                selectOrg={margeOrg.id}
                disabledOrg={orgId}
                callback={(res) => setMargeOrg(res)}
                mode={'edit'}
                isOrgMarge={true}
              />,
            }));
          });
      });
    } else {
      CompanyLogOperation('organizationMargeSelectOrgClick', () => {
        apiCompany.organizations().get()
          .then((res) => {
            CompanyLogOperation('organizationMargeList', () => {
              const orgList = (res as ApiGetOrganizationsResponse).body.data;
              dispatch(dialogAction.push({
                title: '組織指定',
                element: <OrganizationSelectDialog
                  orgData={orgList as any}
                  selectOrg={margeOrg.id}
                  disabledOrg={orgId}
                  callback={(res) => setMargeOrg(res)}
                  mode={'edit'}
                  isOrgMarge={true}
                />,
              }));
            })
          });
      });
    }
  }, [orgId, margeOrg]);
  /** モーダルを閉じる */
  const closeModal = useCallback(() => {
    apiManger === 'isp'
      ? IspLogOperation(
        type === 'add' ? 'organizationManagerAddCancel' : 'organizationManagerEditCancel',
        () => {
          dispatch(dialogAction.pop());
          setIsDisabled(false)
        })
      : CompanyLogOperation(
        type === 'add' ? 'organizationManagerAddCancel' : 'organizationManagerEditCancel',
        () => {
          dispatch(dialogAction.pop());
          setIsDisabled(false)
        });
  }, []);

  // バリデーションのトータルチェック
  const isDisabled = CheckAllValid(validations);

  const onMergeOrg = useCallback( () => {
    if (apiManger === 'isp') {
      IspLogOperation('organizationMargeClick', () => {
        apiIsp.companies(companyCode).organizations(orgState.organizationCode).merger().post({
          to_organization_code: margeOrg.id,
        }).then((res) => {
          dispatch(dialogAction.popAll());
          setIsDisabled(false)
          setUpdateFlag(prevState => !prevState);
        }).catch((e) => {
          ApiManager.errorFunc(e);
        })
      })
    } else {
      CompanyLogOperation('organizationMargeClick', () => {
        apiCompany.organizations(orgState.organizationCode).merger().post({
          to_organization_code: margeOrg.id,
        }).then((res) => {
          dispatch(dialogAction.popAll());
          setIsDisabled(false)
          setUpdateFlag(prevState => !prevState);
        }).catch((e) => {
          ApiManager.errorFunc(e)
        })
      })
    }
  }, [apiManger, companyCode, orgState, margeOrg]);

  /** 確認モーダルの表示 */
  const openModal = useCallback(() => {
    const margeMessage = ['', `この組織を［${margeOrg.label}］と合併すると`, '物件情報も合わせて移動されます', '本当によろしいですか？'];
    const addMessage = ['組織名', `${name}を登録します`];
    const editMessage = (() => {
      const msg = ['組織名', `${name}を変更します`];
      if (isMargeOrg) {
        return msg.concat(margeMessage);
      } else {
        return msg;
      }
    })()
    apiManger === 'isp'
      ? IspLogOperation(type === 'add' ? 'organizationManagerAddCheckClick' : 'organizationManagerEditCheckClick')
      : CompanyLogOperation(type === 'add' ? 'organizationManagerAddCheckClick' : 'organizationManagerEditCheckClick');
    dispatch(dialogAction.pushMessage({
      title: '入力確認',
      message: type === 'add' ? addMessage : editMessage,
      buttons: [
        {
          label: 'キャンセル',
          callback: () => {
            apiManger === 'isp'
              ? IspLogOperation(
                type === 'add' ? 'organizationManagerAddCheckCancel' : 'organizationManagerEditCheckCancel',
                () => {
                  dispatch(dialogAction.pop());
                  setIsDisabled(false)
                  // setValidFlag(!validFlag);
                })
              : CompanyLogOperation(
                type === 'add' ? 'organizationManagerAddCheckCancel' : 'organizationManagerEditCheckCancel',
                () => {
                  dispatch(dialogAction.pop());
                  setIsDisabled(false)
                  setValidFlag(!validFlag);
                });
          },
        },
        {
          label: type === 'add' ? '登録' : '変更',
          callback: () => {
            if (type === 'add') {
              if (apiManger === 'isp') {
                // ISP管理、組織追加
                dispatch(apiIspOrganizationsAction.register({
                  companyCode,
                  param: {
                    organization_name: name,
                    is_not_address_copy: !isNotAddressCopy,
                    zip_code: postalCode,
                    pref_id: addressTop,
                    address: addressMiddle,
                    others: addressLast,
                    parent_organization_code: parentOrganizationCode,
                    parent_hierarchy: parentHierarchy,
                  },
                  callback: {
                    success: () => {
                      dispatch(dialogAction.popAll());
                      setIsDisabled(false)
                      setUpdateFlag(prevState => !prevState);
                    },
                  },
                }));
              } else {
                dispatch(apiCompanyOrganizationsAction.register({
                  param: {
                    organization_name: name,
                    is_not_address_copy: !isNotAddressCopy,
                    zip_code: postalCode,
                    pref_id: addressTop,
                    address: addressMiddle,
                    others: addressLast,
                    parent_organization_code: parentOrganizationCode,
                    parent_hierarchy: parentHierarchy,
                  },
                  callback: {
                    success: () => {
                      dispatch(dialogAction.popAll());
                      setIsDisabled(false)
                      setUpdateFlag(prevState => !prevState);
                    },
                  },
                }));
              }
              ;
            } else {
              if (apiManger === 'isp') {
                // ISP管理、組織編集
                dispatch(apiIspOrganizationsAction.update({
                  companyCode,
                  organization_code: orgState.organizationCode,
                  param: {
                    organization_name: name,
                    is_not_address_copy: !isNotAddressCopy,
                    zip_code: postalCode,
                    pref_id: addressTop,
                    address: addressMiddle,
                    others: addressLast,
                  },
                  callback: {
                    success: () => {
                      if (isMargeOrg) {
                        onMergeOrg()
                      } else {
                        dispatch(dialogAction.popAll());
                        setIsDisabled(false)
                        setUpdateFlag(prevState => !prevState);
                      }
                    },
                  },
                }));
              } else {
                dispatch(apiCompanyOrganizationsAction.update({
                  organization_code: orgState.organizationCode,
                  param: {
                    organization_name: name,
                    is_not_address_copy: !isNotAddressCopy,
                    zip_code: postalCode,
                    pref_id: addressTop,
                    address: addressMiddle,
                    others: addressLast,
                  },
                  callback: {
                    success: () => {
                      if (isMargeOrg) {
                        onMergeOrg()
                      } else {
                        dispatch(dialogAction.popAll());
                        setIsDisabled(false)
                        setUpdateFlag(prevState => !prevState);
                      }
                    },
                  },
                }));
              }
            }
          },
        },
      ],
    }));
  }, [name, postalCode, addressTop, addressMiddle, addressLast, isNotAddressCopy, validFlag, isMargeOrg, margeOrg]);

  useDidMount(() => {
    if (type === 'edit') {
      if (apiManger === 'isp') {
        // ISP管理、組織詳細取得
        apiIsp.companies(companyCode)
          .organizations(orgState.organizationCode)
          .get<ApiGetOrganizationDetailResponse>()
          .then((v: ApiGetOrganizationDetailResponse) => {
            setOrgId(v.body.data.organization_code)
            setName(v.body.data.organization_name);
            setPostalCode(v.body.data.zip_code);
            setAddressTop(v.body.data.pref_id);
            setAddressMiddle(v.body.data.address);
            setAddressLast(v.body.data.others ?? '');
          });
      } else {
        apiCompany.organizations(orgState.organizationCode)
          .get<ApiGetOrganizationDetailResponse>()
          .then((v: ApiGetOrganizationDetailResponse) => {
            setOrgId(v.body.data.organization_code)
            setName(v.body.data.organization_name);
            setPostalCode(v.body.data.zip_code);
            setAddressTop(v.body.data.pref_id);
            setAddressMiddle(v.body.data.address);
            setAddressLast(v.body.data.others ?? '');
          });
      }
    }
  });

  useEffect(() => {
    if (type === 'add') {
      if (apiManger === 'isp') {
        // ISP管理、親階層住所取得
        apiIsp.companies(companyCode)
          .organizations(orgState.organizationCode)
          .address()
          .get()
          .then((v: ApiGetAddressResponse) => {
            setPostalCode(v.body.data.zip_code);
            setAddressTop(v.body.data.pref_id);
            setAddressMiddle(v.body.data.address);
            setParentOrganizationCode(orgState.organizationCode);
            setAddressLast(v.body.data.others ?? '');
          });
      } else {
        apiCompany.organizations(orgState.organizationCode)
          .address()
          .get()
          .then((v: ApiGetAddressResponse) => {
            setPostalCode(v.body.data.zip_code);
            setAddressTop(v.body.data.pref_id);
            setAddressMiddle(v.body.data.address);
            setParentOrganizationCode(orgState.organizationCode);
            setAddressLast(v.body.data.others ?? '');
          });
      }
    }
  }, [isNotAddressCopy]);


  useEffect(() => {
    if (type === 'edit') {
      validations['requireName'] = ValidationFactory('requireEdit');
      validations['require'] = ValidationFactory('requireEdit');
      validations['length30'] = ValidationFactory('length30');
      validations['length50'] = ValidationFactory('length50');
      validations['postCode'] = ValidationFactory('postCode');

    } else {
      validations['requireName'] = ValidationFactory('require');
      validations['require'] = ValidationFactory('requireEdit');
      validations['length30'] = ValidationFactory('length30');
      validations['length50'] = ValidationFactory('length50');
      validations['postCode'] = ValidationFactory('postCode');
    }
  }, [validFlag]);

  return (
    <>
      <div className="organization_edit">
        <div className="edit_box">
          <div className="item_wrap">
            <div className="item_head">
              組織名
              <span className="required">必須</span>
            </div>
            <div className="item_cnt">
              <Input
                value={name}
                boxSize="large"
                onChange={(e) => handleChangeName(e.target.value)}
                validations={[
                  validations.requireName,
                  validations.length50,
                ]}
              />
            </div>
          </div>
          {type === 'add' ? (
            <div className="item_wrap">
              <div className="item_head">
                <div className="checkbox">
                  <Input
                    id="notTakeOverAddress"
                    type="checkBox"
                    onChange={() => handleChangeIsNotAddressCopy(!isNotAddressCopy)}
                  />
                  <label htmlFor="notTakeOverAddress">
                    上層階の住所を引き継がない
                  </label>
                </div>
              </div>
            </div>
          ) : (<></>)}
          <div className={type === 'add' && !isNotAddressCopy ? 'disabled' : ''}>
            <div className="item_wrap">
              <div className="item_head">
                郵便番号
                <span className="required">必須</span>
              </div>
              <div className="item_cnt flex">
                <label>〒</label>
                <Input
                  className="postal_coad"
                  value={postalCode}
                  // placeholder="例）100-0000"
                  boxSize="small"
                  onChange={(e) => handleChangePostalCode(e.target.value)}
                  validations={[
                    validations.require,
                    validations.postCode,
                  ]}
                />
                <span className="comment">
                  半角数字・ハイフンあり
                </span>
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_head">
                都道府県
                <span className="required">必須</span>
              </div>
              <div className="item_cnt select_wrap">
                <Select
                  value={findPrefecture(Number(addressTop))}
                  list={prefectureList}
                  onChange={handleChangeAddressTop}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_head">
                企業住所（市区町村・番地）
                <span className="required">必須</span>
              </div>
              <div className="item_cnt">
                <Input
                  value={addressMiddle}
                  boxSize="large"
                  onChange={(e) => handleChangeAddressMiddle(e.target.value)}
                  validations={[
                    validations.require,
                    validations.length50,
                  ]}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_head">
                企業住所（ビル名等）
              </div>
              <div className="item_cnt">
                <Input
                  value={addressLast}
                  boxSize="large"
                  onChange={(e) => handleChangeAddressLast(e.target.value)}
                  validations={[
                    validations.length50,
                  ]}
                />
              </div>
            </div>
            { type === 'edit' &&
              <>
                <div className="item_wrap">
                  <div className="item_cnt">
                    <Checkbox
                      label={`この組織を他組織に合併させる`}
                      onClick={() => handleChangeIsMargeOrg(!isMargeOrg)}
                      id="mergeOrg"
                      checked={isMargeOrg}
                    />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_cnt">
                    <Button label="組織選択" size="large" disabled={!isMargeOrg} onClick={handleClickMargeOrg} />
                    {margeOrg.id && <span style={{fontSize: '18px', fontWeight: 'bold', color: `${isMargeOrg ? 'black' : 'lightgray'}`}}>{`（${margeOrg.label}）`}</span>}
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <footer>
        <Button
          size="large"
          color="tertiary"
          label="キャンセル"
          onClick={closeModal}
        />
        <Button
          size="large"
          label="確認"
          onClick={openModal}
          disabled={(() => {
            if (isMargeOrg) {
              return !(margeOrg.id && !isDisabled);
            } else {
              return (isDisabled)
            }
          })()}
        />
      </footer>
    </>
  );
};
