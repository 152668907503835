import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import iconCopy from '../../../assets/images/common/icon_copy.svg';
import iconDelete from '../../../assets/images/common/icon_delete.svg';
import iconEdit from '../../../assets/images/common/icon_edit_s.svg';
import iconMailOpen from '../../../assets/images/common/icon_mail_open.svg';
import iconMailClose from '../../../assets/images/common/icon_mail_close.svg';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { RoutingPath } from '../../../routes/routing-path';
import { dialogAction } from '../../../slices/dialog-slice';
import { Table } from '../../table/table';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { Button } from '../../ui/button/button';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import './information-detail.scss';
import { push } from 'connected-react-router';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { InformationEditor } from './information-editor';
import { apiIsp, apiCompany, ApiManager } from '../../../managers/api-manager';
import { useParams } from 'react-router-dom';
import {
  InfomationDetailEntity,
  InfomationLevelDataEntity,
  InfomationCategoryDataEntity,
  NotificationDetaEntity,
} from './type/infomation.type';
import { useDidMount } from '../../../hooks/life-cycle';
import { SkeletonLabel } from '../../ui/label/label';
import { DateFormatter } from '../../../utilities/date-formatter';
import { useConfirmation } from '../../../hooks/use-confirmation';
import iconLink from '../../../assets/images/common/icon_link.svg';
import { InforMationEditDialog } from '../../dialog/infomation-edit-dialog';
import { informationAction } from '../../../slices/information-slice';
import { cloneDeep } from 'lodash';
import { useAsyncLogOperation, useAutoSelectLogOperation } from '../../../hooks/use-async-log-operation';
import { useIsCompany } from '../../../hooks/use-manager-type';
import { RichText } from '../../ui/rich-text/rich-text';

const breadcrumbList = (type: 'company' | 'isp') => type === 'company' ? [
  breadcrumbParts.company.home,
  breadcrumbParts.company.info,
  breadcrumbParts.company.infoDetail,
] : [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.ispInfo,
  breadcrumbParts.isp.ispInfoDetail,
];

const formatEmpty = (v: string | null | undefined) => v ?? '';

type Props = {
  data: InfomationDetailEntity | null;
  apiType: 'isp' | 'company';
}

const DetailBox = (props: Props) => {
  const { data, apiType } = props;

  /* State */
  // 開閉状態
  const [open, setOpen] = useState(true);
  const [levelList, setLevelList] = useState<InfomationLevelDataEntity[] | null>(null);
  const [categoryList, setCategoryList] = useState<InfomationCategoryDataEntity[] | null>(null);

  /* Memo */
  const api = useMemo(() => apiType === 'company' ? apiCompany : apiIsp, [apiType])
  const level = useMemo(() => {
    if (data && levelList) {
      return levelList.find((v) => v.id === data.level)?.name ?? '';
    } else {
      return '';
    }
  }, [data, levelList]);
  const category = useMemo(() => {
    if (data && categoryList) {
      return categoryList.find((v) => v.id === data.category)?.name ?? '';
    } else {
      return '';
    }
  }, [data, categoryList]);
  const notificationDate = useMemo(() => {
    if (!data) return '';
    if (!data.notification_start_date || !data.notification_end_date) return '';
    const start = DateFormatter.str2str(data.notification_start_date, undefined, '/');
    const end = DateFormatter.str2str(data.notification_end_date, undefined, '/');
    return `${start}　～　${end}`;
  }, [data]);
  const createAt = useMemo(() => {
    if (!data) return '';
    if (!data.created_at || !data.created_display_name) return '';
    const date = DateFormatter.str2str(data.created_at, 'YYYYMMDD_HHmm');
    return `${date}　${data.created_display_name}`;
  }, [data]);
  const updateAt = useMemo(() => {
    if (!data) return '';
    if (!data.updated_at || !data.updated_display_name) return '';
    const date = DateFormatter.str2str(data.updated_at, 'YYYYMMDD_HHmm');
    return `${date}　${data.updated_display_name}`;
  }, [data]);

  const getCategoryList = useCallback(async () => {
    const data = await api.informations().categories().get();
    const body = data.body.data;
    setCategoryList(body);
  }, []);

  const getLevelList = useCallback(async () => {
    const data = await api.informations().levels().get();
    const body = data.body.data;
    setLevelList(body);
  }, []);
  
  useDidMount(() => {
    getCategoryList();
    getLevelList();
  });
  
  return (
    <div className="detail_box edit_wrap">
      <div className="detail_box__inner edit_box">
        <div className="first_part">
          <div className="item_wrap">
            <div className="item_head">タイトル</div>
            <div className="item_cnt">
              <SkeletonLabel
                str={formatEmpty(data?.title)}
                isLoading={!!data}
              />
            </div>
          </div>
          <hr />
          <div className="item_wrap">
            <div className="item_head">内容</div>
            <div className="item_cnt">
              {/* <SkeletonLabel
                str={formatEmpty(data?.body)}
                isLoading={!!data}
              /> */}
              <RichText 
                callback={() => {}}
                isReadOnly
                initData={data?.body ? JSON.parse(data.body) : undefined}
              />
            </div>
          </div>
        </div>
        {open &&
          <div className="second_part">
            <div className="second_part__inner">
              <hr />
              <div className="item_wrap">
                <div className="item_head">カテゴリ</div>
                <div className="item_cnt">
                  <SkeletonLabel
                    str={category}
                    isLoading={!!(data && categoryList)}
                  />
                </div>
              </div>
              <hr />
              <div className="item_wrap">
                <div className="item_head">通知レベル</div>
                <div className="item_cnt">
                  <SkeletonLabel
                    str={level}
                    isLoading={!!(data && levelList)}
                  />
                </div>
              </div>
              <hr />
              <div className="item_wrap">
                <div className="item_head">通知URL</div>
                <div className="item_cnt">
                  {
                    (!!data?.is_url && !!data?.url) && (
                      <div>
                        {data.url}
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(data?.url ?? '');
                          }}
                          src={iconLink}
                          alt=""
                        />
                      </div>
                    )
                  }
                </div>
              </div>
              <hr />
              <div className="item_wrap">
                <div className="item_head">通知開始日 / 通知終了日</div>
                <div className="item_cnt">
                  <SkeletonLabel
                    str={notificationDate}
                    isLoading={!!data}
                  />
                </div>
              </div>
              <hr />
              <div className="item_wrap">
                <div className="item_head">作成日 / 作成者（変更不可）</div>
                <div className="item_cnt">
                  <SkeletonLabel
                    str={createAt ?? ''}
                    isLoading={!!data}
                  />
                </div>
              </div>
              <hr />
              <div className="item_wrap">
                <div className="item_head">更新日 / 更新者（最終更新）</div>
                <div className="item_cnt">
                  <SkeletonLabel
                    str={updateAt ?? ''}
                    isLoading={!!data}
                  />
                </div>
              </div>
              <hr />
              <div className="item_wrap">
                <div className="item_head">差出人</div>
                <div className="item_cnt">
                  <SkeletonLabel
                    str={data?.sender_name ?? ''}
                    isLoading={!!data}
                  />
                </div>
              </div>
            </div>
          </div>
        }
        <div
          className={`detail_box__toggle${open ? '' : ' close'}`}
          onClick={() => setOpen(!open)}
        />
      </div>
    </div>
  );
};

export const InformationDetail = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  /* Hooks */
  const dispatch = useAppDispatch();
  const id = useParams<{ infoId: string }>().infoId;
  const operation = useAsyncLogOperation();
  const isCompany = useIsCompany(apiManger.type);
  const baseLog = useMemo(() => {
    return isCompany ? operation.company : operation.isp;
  }, [isCompany, operation]);
  const [detailData, setDetailData] = useState<InfomationDetailEntity | null>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const logMng = useAutoSelectLogOperation(apiManger.type);

  const baseApi = useMemo(() => apiManger.type === 'company' ? apiCompany : apiIsp, [apiManger])
  /* Callback */
  const onChangeDisabled = useCallback((v: boolean) => {
    setIsDisabled(!v);
  }, [isDisabled]);
  // - 削除ボタン -
  const onClickDelete = useCallback(async() => {
    if (!detailData) return;
    await baseLog('informationListDetailDeleteClick');
    dispatch(dialogAction.pushMessage({
      title: '削除確認',
      message: [
        `インフォメーションを削除しますか？`,
      ],
      buttons: [
        {
          label: 'キャンセル',
          callback: async() => {
            await baseLog('informationListDetailDeleteCancel');
            dispatch(dialogAction.pop());
          },
          color: 'tertiary',
        },
        {
          label: 'OK',
          callback: async () => {
            await baseApi.informations(detailData.info_id).delete();
            dispatch(dialogAction.pushMessage({
              title: '確認',
              message: ['削除が完了しました。'],
              buttons: [{ label: 'OK', callback: () => {
                dispatch(dialogAction.pop());
                const pushUrl = apiManger.type === 'company' ? RoutingPath.companyInfo : RoutingPath.ispInfo;
                dispatch(push(pushUrl));    
              }}],
            }))
          },
        },
      ],
    }));
  }, [detailData, baseLog]);

  // - 複製ボタン -
  const onClickCopy = useCallback(() => {
    // TODO pathの変更をする//
    apiManger.type === 'company' ?
      CompanyLogOperation('informationListDetailCopyClick', () => {
        dispatch(push(RoutingPath.companyInfoClone.replace(':infoId', id)));
      }) :
      IspLogOperation('informationListDetailCopyClick', () => {
        dispatch(push(RoutingPath.ispInfoClone.replace(':infoId', id)));
      });
  }, [detailData]);

  // - 編集ボタン -
  const onClickEdit = useCallback(async() => {
    if (!id) return;
    await baseLog('informationListDetailEditClick');
    dispatch(dialogAction.push({
      title: 'インフォメーション編集',
      element:
        <div className="info_edit_dialog__body">
          <InforMationEditDialog
            id={id}
            apiManger={apiManger}
            isDisabled={isDisabled}
            checkValid={(bool) => {
              onChangeDisabled(bool);
            }}
            initParam={detailData}
            callback={() => {
              (baseApi as any).informations(id).get().then((v: any) => {
                const data = ((v?.body?.data as any)?.data) as InfomationDetailEntity | null;
                setDetailData(data ?? null);
              });          
            }}
          />
        </div>
      ,
      className: 'info_edit_dialog',
    }));
  }, [detailData, apiManger, isDisabled, id]);
  // - DLボタン -
  const onClickDL = useCallback(() => {
    // logMng('logManagerDlClick');
    if (apiManger.type === 'isp') {
      (apiIsp.informations(id).destinations().get() as Promise<Blob>)
        .then((v) => {
          const a = document.createElement('a');
          const url = URL.createObjectURL(v);
          a.download = 'インフォメーション通知先.csv';
          a.href = url;
          a.click();
          URL.revokeObjectURL(url);
          a.remove();
        }).catch((error) => {
        ApiManager.errorFunc(error);
      });
    } else {
      (apiCompany.informations(id).destinations().get() as Promise<Blob>)
        .then((v) => {
          const a = document.createElement('a');
          const url = URL.createObjectURL(v);
          a.download = 'インフォメーション通知先.csv';
          a.href = url;
          a.click();
          URL.revokeObjectURL(url);
          a.remove();
        }).catch((error) => {
        ApiManager.errorFunc(error);
      });
    }
  }, [id]);
  // - 再指定ボタン -
  const onClickReSelect = useCallback(() => {
    // info && dispatch(informationAction.setInformationData(info));
    if (detailData) {
      const list = detailData?.sender_list.map((v) => {
        const array: NotificationDetaEntity = {
          company_code: v.company_code,
          company_name: v.company_name,
          display_name: v.member_display_name,
          email: v.email,
          member_id: v.member_id,
        };
        return array;
      });
      list && dispatch(informationAction.setSendList(list));
    }
    // isInit = false
    // setIsInit(false)
    apiManger.type === 'company' ?
      CompanyLogOperation('informationCreateNotificationClick', () => {
        dispatch(push(`${RoutingPath.companyInfoSelect}?m=edit&i=${id}`));
      }) :
      IspLogOperation('informationCreateNotificationClick', () => {
        dispatch(push(`${RoutingPath.ispInfoSelect}?m=edit&i=${id}`));
      });
  }, [detailData]);

  const tableHead = [
    apiManger.type === 'company' ? '組織名' : '企業名',
    '企業ユーザー名',
    'メールアドレス',
    '開封状況',
    '開封日',
  ];

  const tableBody = useMemo(() => {
    if (!detailData) return [];
    return detailData.sender_list.map((v) => [
      v.company_name,
      v.member_display_name,
      v.email,
      <div><img
        src={v.opened_at ? iconMailOpen : iconMailClose}
        alt=""
      /></div>,
      v.opened_at ? DateFormatter.str2str(v.opened_at, 'YYYYMMDD_HHmm', '/') ?? '' : '',
    ]);
  }, [detailData]);

  useEffect(() => {
    (baseApi as any).informations(id).get().then((v: any) => {
      const data = ((v?.body?.data as any)?.data) as InfomationDetailEntity | null;
      setDetailData(data ?? null);
    });
  }, [id]);

  return (
    <div
      id="App"
      className="information_detail detail"
    >
      <SideBar
        currentPage="information-list"
        apiManger={apiManger.type}
      />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList(apiManger.type)} />
          <div className="inner">
            <section className="information_detail__top">
              <header>
                <h2>インフォメーション詳細</h2>
                <div
                  style={{ marginLeft: 'auto' }}
                  className="information_detail__top__actions"
                >
                  <Button
                    label="編集"
                    onClick={onClickEdit}
                    color="secondary"
                    icon={<img
                      alt=""
                      src={iconEdit}
                    />}
                  />
                  <Button
                    label="複製"
                    onClick={onClickCopy}
                    icon={<img
                      alt=""
                      src={iconCopy}
                    />}
                  />
                  <Button
                    label="削除"
                    onClick={onClickDelete}
                    color="quaternary"
                    icon={<img
                      alt=""
                      src={iconDelete}
                    />}
                  />
                </div>
              </header>
              <DetailBox data={detailData} apiType={apiManger.type}/>
            </section>
            <section className="information_detail__bottom">
              <header>
                <h2>通知先ユーザー一覧</h2>
                <div className="count">
                  <span>件数</span>
                  {detailData?.sender_list.length ?? '-'}
                  <span>件</span>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    label="DL"
                    onClick={onClickDL}
                  />
                </div>
                <div style={{ marginLeft: '1rem' }}>
                  <Button
                    label="再指定"
                    onClick={onClickReSelect}
                  />
                </div>
              </header>
              <div className="table_box">
                <Table
                  head={tableHead}
                  body={tableBody}
                  alignList={['l', 'l', 'l', 'c', 'c']}
                  setSortBy={() => {
                  }}
                  setHighlow={() => {
                  }}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
