import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { dialogAction } from '../../slices/dialog-slice';
import iconEnvelope from '../../assets/images/common/icon_envelope.svg';



type SendMailDialogProps = {
  message: string[],
  callback: () => void,
};

let called = false;

const SendMailDialogComponent = (props: SendMailDialogProps) => {
  const dispatch = useDispatch();
  const { message, callback } = props;
  const handlerClickBody = useCallback(() => {
    document.body.removeEventListener('click', handlerClickBody);
    callback();
    called = true;
    dispatch(dialogAction.pop());
  }, []);
  useEffect(() => {
    called = false;
    document.body.addEventListener('click', handlerClickBody);
    /*setTimeout(() => {
      if (!called) {
        handlerClickBody();
      }
    }, 3000);*/
  }, []);
  return (
    <div className="for_send_mail">
      <img src={iconEnvelope} alt="" />
      <div className="text_box">
        {message.map((v, i) => (
          <div key={`send-mail-dialog-message_${i}`}>{v}</div>
        ))}
      </div>
    </div>
  );
};

export const SendMailDialog = (message: string[], callback: () => void) => <SendMailDialogComponent message={message} callback={callback} />;
