const KEY = 'debug-is-login';

export const debugIsLogin = () => {
  return localStorage.getItem(KEY);
};

export const toggleDebugIsLogin = (isLogin?: boolean, isIsp: '1' | '2' = '1') => {
  if (isLogin) {
    localStorage.setItem(KEY, isIsp);
  } else {
    localStorage.removeItem(KEY);
  }
}