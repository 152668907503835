import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { Select } from '../../ui/select/select';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { dialogAction } from '../../../slices/dialog-slice';
import { OrganizationSelectDialog } from '../../dialog/organization-select-dialog';
import { apiCompany, apiIsp, ApiManager } from '../../../managers/api-manager';
import { useDidMount } from '../../../hooks/life-cycle';
import { apiIspCommonAction } from '../../../slices/api-isp/api-isp-common-slicer';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { CompanyData } from '../../../models/company-data';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import { apiCompanyCommonAction } from '../../../slices/api-company/api-company-common-slicer';
import { companyAction, CompanyParams } from '../../../slices/company-slice';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { ApiGetOrganizationsResponse } from '../../../api/api/isp-web/companies/organizations/api-get-organizations.response';
import { ApiGetCompanyDetailResponse as ApiGetIspCompanyDetailResponse } from '../../../api/api/isp-web/companies/api-get-company-detail.response';
import { ApiGetCompanyDetailResponse } from '../../../api/api/company-web/companies/api-get-company-detail.response';
const ispBreadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.companyList,
  breadcrumbParts.isp.companyDetail,
  breadcrumbParts.isp.companyUserList,
  breadcrumbParts.isp.companyUserAdd,
];
const companyBreadcrumbList = [
  breadcrumbParts.company.home,
  breadcrumbParts.company.userList,
  breadcrumbParts.company.userAdd,
];
type Props = {
  companyCode?: string,
} & PageComponentDefaultProps;

const validations: { [key: string]: Validation } = {
  requireOrgData: ValidationFactory('require'),
  requireEmail: ValidationFactory('require'),
  requirePosition: ValidationFactory('require'),
  length256: ValidationFactory('length256'),
};

export const CompanyInvitationUser = (props: Props) => {
  const { apiManger, companyCode } = props;
  const dispatch = useDispatch();
  const { industryList, occupationList } = useAppSelector((state) => ({
    ...state.commonData,
  }), lodash.isEqual);
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);
  const { breadcrumbName } = useAppSelector((state) => state.company);

  // -------------------- state --------------------
  const [breadcrumbList, setBreadcrumbList] = useState(
    apiManger.type === 'isp' ?
      ispBreadcrumbList :
      companyBreadcrumbList,
  );
  const [orgData, setOrgData] = useState<{ id: string, label: string; }>({ id: '', label: '' });
  const [email, setEmail] = useState('');
  const [occupation, setOccupation] = useState('');
  const [authority, setAuthority] = useState(2);
  const [information, setInformation] = useState(false);
  const [logReference, setLogReference] = useState(false);
  const [cadectBasis, setCadectBasis] = useState(false);
  const [cadectEco, setCadectEco] = useState(false);
  const [cadectAir, setCadectAir] = useState(false);
  const [cadectDisaster, setCadectDisaster] = useState(false);
  const [cadect1UseCount, setCadect1UseCount] = useState(0);
  const [cadect2UseCount, setCadect2UseCount] = useState(0);
  const [cadect3UseCount, setCadect3UseCount] = useState(0);
  const [cadect4UseCount, setCadect4UseCount] = useState(0);
  const [infoUseCount, setInfoUseCount] = useState(0);
  const [logUseCount, setLogUseCount] = useState(0);
  const [resolveFlag, setResolveFlag] = useState(false);
  const [validFlag, setValidFlag] = useState(false);
  const [companyData, setCompanyData] = useState<CompanyParams>();
  // -------------------- イベント --------------------
  /** 組織指定押下 */
  const handleClickOrgDesignation = useCallback(
    () => {
      if (apiManger.type === 'isp') {
        IspLogOperation('companyUserAddOrganizationSelect', () => {
          apiIsp.companies(companyCode).organizations().get()
            .then((res) => {
              IspLogOperation('companyUserAddOrganizationList')
              const orgList = (res as ApiGetOrganizationsResponse).body.data;
              dispatch(dialogAction.push({
                title: '組織指定',
                element: <OrganizationSelectDialog
                  orgData={orgList as any}
                  selectOrg={orgData.id}
                  callback={(res) => {
                    IspLogOperation('companyUserAddOrganizationAddClick')
                    setOrgData(res);
                  }}
                  mode={'add'}
                />,
              }));
            });
        });
      } else {
        CompanyLogOperation('companyUserAddOrganizationSelect', () => {
          apiCompany.organizations().get()
            .then((res) => {
              CompanyLogOperation('companyUserAddOrganizationList')
              const orgList = (res as ApiGetOrganizationsResponse).body.data;
              dispatch(dialogAction.push({
                title: '組織指定',
                element: <OrganizationSelectDialog
                  orgData={orgList as any}
                  selectOrg={orgData.id}
                  callback={(res) => {
                    CompanyLogOperation('companyUserAddOrganizationAddClick')
                    setOrgData(res);
                  }}
                  mode={'add'}
                />,
              }));
            });
        });
      }
    },
    [orgData],
  );
  /** メールアドレス変更 */
  const handleChangeEmail = useCallback(
    (v) => {
      setEmail(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
    },
    [],
  );
  /** 職種セレクト */
  const handleChangeOccupation = useCallback(
    (v) => {
      setOccupation(v);
    },
    [],
  );
  /** 権限セレクト */
  const handleChangeAuthority = useCallback(
    (v) => {
      if (v !== 1) {
        if (information) {
          setInformation(false);
          setInfoUseCount(prev => --prev);
        }
        if (logReference) {
          setLogReference(false);
          setLogUseCount(prev => --prev);
        }
      }
      setAuthority(v);
    },
    [information, logReference, infoUseCount, logUseCount],
  );
  /** インフォメーション変更 */
  const handleChangeInformation = useCallback(
    (v) => {
      let count = infoUseCount;
      v ? count += 1
        : count -= 1;
      setInfoUseCount(count);
      setInformation(v);
    },
    [infoUseCount],
  );
  /** ログ変更 */
  const handleChangeLogReference = useCallback(
    (v) => {
      let count = logUseCount;
      v ? count += 1
        : count -= 1;
      setLogUseCount(count);
      setLogReference(v);
    },
    [logUseCount],
  );
  /** cadect基本利用変更 */
  const handleChangeCadectBasis = useCallback(
    (v) => {
      let count = cadect1UseCount;
      v ? count += 1
        : count -= 1;
      setCadect1UseCount(count);
      setCadectBasis(v);
      // 基本利用がチェックアウトされた時全てチェックアウトする
      if (!v) {
        setCadectEco(v);
        cadectEco && setCadect2UseCount((prev) => prev - 1);
        setCadectAir(v);
        cadectAir && setCadect3UseCount((prev) => prev - 1);
        setCadectDisaster(v);
        cadectDisaster && setCadect4UseCount((prev) => prev - 1);
      }
    },
    [cadect1UseCount,
      cadect2UseCount,
      cadect3UseCount,
      cadect4UseCount],
  );
  /** cadect省エネ利用変更 */
  const handleChangeCadectEco = useCallback(
    (v) => {
      let count = cadect2UseCount;
      v ? count += 1
        : count -= 1;
      setCadect2UseCount(count);
      setCadectEco(v);
    },
    [cadect2UseCount],
  );
  /** cadect空調利用変更 */
  const handleChangeCadectAir = useCallback(
    (v) => {
      let count = cadect3UseCount;
      v ? count += 1
        : count -= 1;
      setCadect3UseCount(count);
      setCadectAir(v);
    },
    [cadect3UseCount],
  );
  /** cadect災害利用変更 */
  const handleChangeCadectDisaster = useCallback(
    (v) => {
      let count = cadect4UseCount;
      v ? count += 1
        : count -= 1;
      setCadect4UseCount(count);
      setCadectDisaster(v);
    },
    [cadect4UseCount],
  );
  /** キャンセル押下 */
  const handleClickCancel = useCallback(
    () => {
      if (apiManger.type === 'isp') {
        IspLogOperation('companyUserAddCancel', () => {
          const path = RoutingPath.ispCompanyUserList
            .replace(':companyCode', companyCode!);
          const query = QueryParamFormatter.queryParse();
          const _query = QueryParamFormatter.queryCompose(query);
          dispatch(push(path + '?' + _query));
        });
      } else {
        CompanyLogOperation('companyUserAddCancel', () => {
          const path = RoutingPath.companyUserList;
          const query = QueryParamFormatter.queryParse();
          const _query = QueryParamFormatter.queryCompose(query);
          dispatch(push(path + '?' + _query));
        });
      }
    },
    [],
  );

  // バリデーションのトータルチェック
  // const isDisabled = CheckAllValid(validations)
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  const orgRef = useRef<HTMLInputElement>(null);
  const [orgFlag, setOrgFlag] = useState(false);

  // 組織指定のバリデーションチェック
  const checkValidOrgInput = useCallback(() => {
    if (!orgRef.current) {
      return;
    }
    orgRef.current.focus();
    orgRef.current.blur();
    setIsDisabled(CheckAllValid(validations));
  }, [isDisabled]);
  useEffect(() => {
    if (orgFlag === false) {
      return setOrgFlag(true);
    }
    checkValidOrgInput();
  }, [orgData]);

  /** 招待押下 */
  const handleClickInvitation = () => {
    setIsDisabled(true);
    if (apiManger.type === 'isp') {
      apiIsp.companies(companyCode).members().post({
        organization_code: orgData.id,
        email: email,
        industry_id: 10,
        occupation_id: Number(occupation),
        role: authority,
        is_information: information,
        is_log_admin: logReference,
        is_cadect1: cadectBasis,
        is_cadect2: cadectEco,
        is_cadect3: cadectAir,
        is_cadect4: cadectDisaster,
        pass: `#/admin/companies/${companyCode}/members/add/`,
      }).then((res) => {
          if (res.header.status_code === 200) {
            dispatch(dialogAction.pushSendMail({
              message: [
                '追加したメールアドレスへ',
                '新規登録画面の案内が届きます',
              ],
              callback: () => {
                const path = RoutingPath.ispCompanyUserList
                  .replace(':companyCode', companyCode!);
                const query = QueryParamFormatter.queryParse();
                const _query = QueryParamFormatter.queryCompose(query);
                dispatch(push(path + '?' + _query));
              },
            }));
          }
        },
      )
        .catch((e) => {
          ApiManager.errorFunc(e);
          setIsDisabled(false);
        });
    } else {
      apiCompany.members().post({
        organization_code: orgData.id,
        email: email,
        industry_id: 10,
        occupation_id: Number(occupation),
        role: authority,
        is_information: information,
        is_log_admin: logReference,
        is_cadect1: cadectBasis,
        is_cadect2: cadectEco,
        is_cadect3: cadectAir,
        is_cadect4: cadectDisaster,
        pass: `#/company/members/add/`,
      }).then((res) => {
          if (res.header.status_code === 200) {
            dispatch(dialogAction.pushSendMail({
              message: [
                '追加したメールアドレスへ',
                '新規登録画面の案内が届きます',
              ],
              callback: () => {
                const path = RoutingPath.companyUserList;
                const query = QueryParamFormatter.queryParse();
                const _query = QueryParamFormatter.queryCompose(query);
                dispatch(push(path + '?' + _query));
              },
            }));
          }
        },
      )
        .catch((e) => {
          ApiManager.errorFunc(e);
          setIsDisabled(false);
        });
    }
  };
  // const industry = [...industryList].map((v) => ({
  //   key: String(v.id),
  //   label: v.name,
  // }));
  const occupations = useMemo(() => {
    return [...occupationList].map((v) => ({
      key: String(v.id),
      label: v.name,
    }));
  }, [occupationList]);
  // -------------------- effect --------------------
  useEffect(() => {
    setIsDisabled(CheckAllValid(validations));
  }, [occupation, email, validFlag]);
  /** 企業情報取得（操作権限の分母取得のため） */
  useEffect(() => {
    let unmounted = false;
    if (apiManger.type === 'isp') {
      // ISP管理
      if (!userInfo.user_id) {
        return;
      }
      if (breadcrumbName) {
        const userListPath = RoutingPath.ispCompanyUserList
          .replace(':companyCode', companyCode!);
        breadcrumbParts.isp.companyUserList.route = userListPath;
        const detailPath = RoutingPath.ispCompanyDetail
          .replace(':companyCode', companyCode!);
        breadcrumbParts.isp.companyDetail.route = detailPath;
        breadcrumbParts.isp.companyDetail.label = breadcrumbName.length > 20 ?
          breadcrumbName.slice(0, 20) + '...' :
          breadcrumbName;
        setBreadcrumbList([
          breadcrumbParts.isp.home,
          breadcrumbParts.isp.companyList,
          breadcrumbParts.isp.companyDetail,
          breadcrumbParts.isp.companyUserList,
          breadcrumbParts.isp.companyUserAdd,
        ]);
        setResolveFlag(true);
      }
      (apiIsp.companies(companyCode).get() as Promise<ApiGetIspCompanyDetailResponse>)
        .then((v) => {
          if (!unmounted) {
            const data: CompanyParams = v.body.data;
            setCompanyData(v.body.data);
            setCadect1UseCount(data?.user_cadect1_use_count!);
            setCadect2UseCount(data?.user_cadect2_use_count!);
            setCadect3UseCount(data?.user_cadect3_use_count!);
            setCadect4UseCount(data?.user_cadect4_use_count!);
            setInfoUseCount(data?.user_info_use_count!);
            setLogUseCount(data?.user_log_use_count!);
            if (!breadcrumbName) {
              const userListPath = RoutingPath.ispCompanyUserList
                .replace(':companyCode', companyCode!);
              breadcrumbParts.isp.companyUserList.route = userListPath;
              const detailPath = RoutingPath.ispCompanyDetail
                .replace(':companyCode', companyCode!);
              dispatch(companyAction.setBreadcrumbName({
                breadcrumbName: data.company_name,
              }));
              breadcrumbParts.isp.companyDetail.route = detailPath;
              breadcrumbParts.isp.companyDetail.label = data.company_name.length > 20 ?
                data.company_name.slice(0, 20) + '...' :
                data.company_name;
              setBreadcrumbList([
                breadcrumbParts.isp.home,
                breadcrumbParts.isp.companyList,
                breadcrumbParts.isp.companyDetail,
                breadcrumbParts.isp.companyUserList,
                breadcrumbParts.isp.companyUserAdd,
              ]);
              setResolveFlag(true);
            }
          }
        });
    } else {
      // 企業管理
      if (!companyUserInfo.member_id) {
        return;
      }
      (apiCompany.companies().get() as Promise<ApiGetCompanyDetailResponse>)
        .then((v) => {
          if (!unmounted) {
            const data = v.body.data;
            setCompanyData(v.body.data as any);
            setCadect1UseCount(data?.cadect1_count!);
            setCadect2UseCount(data?.cadect2_count!);
            setCadect3UseCount(data?.cadect3_count!);
            setCadect4UseCount(data?.cadect4_count!);
            setInfoUseCount(data?.info_count!);
            setLogUseCount(data?.log_count!);
          }
        });
      setBreadcrumbList(companyBreadcrumbList);
      setResolveFlag(true);
    }
    setValidFlag(true);
    return () => {
      unmounted = true;
    };
  }, []);

  useDidMount(() => {
    if (apiManger.type === 'isp') {
      if (!userInfo.user_id) {
        return;
      }
      // dispatch(apiIspCommonAction.getIndustryList());
      dispatch(apiIspCommonAction.getOccupationList());
    } else {
      if (!companyUserInfo.member_id) {
        return;
      }
      // dispatch(apiCompanyCommonAction.getIndustryList());
      dispatch(apiCompanyCommonAction.getOccupationList());
    }
  });
  /** バリデーション更新 */
  useEffect(() => {
    validations['requireOrgData'] = ValidationFactory('require');
    validations['requireEmail'] = ValidationFactory('require');
    validations['requirePosition'] = ValidationFactory('require');
    validations['length256'] = ValidationFactory('length256');
  }, [validFlag]);

  return (
    <div
      id="App"
      className="company edit"
    >
      <SideBar
        currentPage={apiManger.type === 'company' ? 'company-user-add' : 'company-list'}
        apiManger={apiManger.type}
      />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          {breadcrumbName || resolveFlag ?
            <BreadcrumbList breadcrumbList={breadcrumbList} /> :
            <></>}
          <section>
            <header>
              <h2>
                ユーザー 新規登録
              </h2>
            </header>
            <div className="edit_wrap">
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">
                    組織名
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt flex">
                    <div className="add_reset_wrap position_relative">
                      <Input
                        className="add_reset"
                        style={{
                          marginRight: '10px',
                          cursor: 'pointer',
                        }}
                        boxSize="medium"
                        readOnly
                        onClick={handleClickOrgDesignation}
                        value={orgData.label}
                        validations={[
                          validations.requireOrgData,
                        ]}
                        ref={orgRef}
                      />
                      <Button
                        size="small"
                        custom="reset"
                        label=""
                        onClick={() => setOrgData({ id: '', label: '' })}
                      />
                    </div>
                    <Button
                      label="組織指定"
                      onClick={handleClickOrgDesignation}
                    />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    メールアドレス
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Input
                      type="email"
                      boxSize="medium"
                      value={email}
                      onChange={(e) => handleChangeEmail(e.target.value)}
                      validations={[
                        validations.requireEmail,
                        validations.length256,
                      ]}
                    />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    職種
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Select
                      value={occupation}
                      list={occupations}
                      onChange={handleChangeOccupation}
                      placeholder=" "
                      validations={[
                        validations.requirePosition,
                      ]}
                    />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    ユーザー権限
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <div className="radio_style">
                      <Input
                        id="authority_manager"
                        type="radio"
                        name="authority"
                        onChange={() => handleChangeAuthority(1)}
                        checked={authority === 1}
                      />
                      <label htmlFor="authority_manager">管理ユーザー</label>
                    </div>
                    <div className="radio_style">
                      <Input
                        id="authority_user"
                        type="radio"
                        name="authority"
                        onChange={() => handleChangeAuthority(2)}
                        checked={authority === 2}
                      />
                      <label htmlFor="authority_user">一般</label>
                    </div>
                    <div className="radio_style">
                      <Input
                        id="authority_guest"
                        type="radio"
                        name="authority"
                        onChange={() => handleChangeAuthority(3)}
                        checked={authority === 3}
                      />
                      <label htmlFor="authority_guest">ゲスト</label>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    操作権限
                  </div>
                  <div className="item_cnt">
                    <div className="indent">
                      <div
                        className={`checkbox ${!information &&
                        (infoUseCount === companyData?.info_use_count) || (authority !== 1) ?
                          'disabled' :
                          ''}`}
                      >
                        <Input
                          id="information"
                          type="checkBox"
                          onChange={() => handleChangeInformation(!information)}
                          checked={information}
                        />
                        <label htmlFor="information">
                          インフォメーション利用
                          （{infoUseCount}名設定済み / {companyData?.info_use_count}名）
                        </label>
                      </div>
                      <div
                        className={`checkbox ${!logReference &&
                        (logUseCount === companyData?.log_use_count) || (authority !== 1) ?
                          'disabled' :
                          ''}`}
                      >
                        <Input
                          id="reference"
                          type="checkBox"
                          onChange={() => handleChangeLogReference(!logReference)}
                          checked={logReference}
                        />
                        <label htmlFor="reference">
                          ログ参照利用
                          （{logUseCount}名設定済み / {companyData?.log_use_count}名）
                        </label>
                      </div>
                    </div>
                    <div className="edit_box for_cadect">
                      <div className="item_wrap fix">
                        <div className="item_cnt">
                          <div
                            className={`checkbox ${!cadectBasis &&
                            cadect1UseCount === companyData?.cadect1_use_count ?
                              'disabled' :
                              ''}`}
                          >
                            <Input
                              id="cadectBasis"
                              type="checkBox"
                              onChange={() => handleChangeCadectBasis(!cadectBasis)}
                              checked={cadectBasis}
                            />
                            <label htmlFor="cadectBasis">
                              CADECT-（基本）利用
                              （{cadect1UseCount}名設定済み / {companyData?.cadect1_use_count}名）
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className={`item_wrap ${cadectBasis ? '' : 'disabled'}`}>
                        <div className="item_cnt">
                          <div
                            className={`checkbox ${!cadectEco &&
                            cadect2UseCount === companyData?.cadect2_use_count ?
                              'disabled' :
                              ''}`}
                          >
                            <Input
                              id="cadectEco"
                              type="checkBox"
                              onChange={() => handleChangeCadectEco(!cadectEco)}
                              checked={cadectEco}
                            />
                            <label htmlFor="cadectEco">
                              CADECT-（省エネ）利用
                              （{cadect2UseCount}名設定済み / {companyData?.cadect2_use_count}名）
                            </label>
                          </div>
                          <div
                            className={`checkbox ${!cadectAir &&
                            cadect3UseCount === companyData?.cadect3_use_count ?
                              'disabled' :
                              ''}`}
                          >
                            <Input
                              id="cadectAir"
                              type="checkBox"
                              onChange={() => handleChangeCadectAir(!cadectAir)}
                              checked={cadectAir}
                            />
                            <label htmlFor="cadectAir">
                              CADECT-（空調）利用
                              （{cadect3UseCount}名設定済み / {companyData?.cadect3_use_count}名）
                            </label>
                          </div>
                          <div
                            className={`checkbox ${!cadectDisaster &&
                            cadect4UseCount === companyData?.cadect4_use_count ?
                              'disabled' :
                              ''}`}
                          >
                            <Input
                              id="cadectDisaster"
                              type="checkBox"
                              onChange={() => handleChangeCadectDisaster(!cadectDisaster)}
                              checked={cadectDisaster}
                            />
                            <label htmlFor="cadectDisaster">
                              CADECT-（防災）利用
                              （{cadect4UseCount}名設定済み / {companyData?.cadect4_use_count}名）
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn_box align_center">
                <Button
                  size="large"
                  color="tertiary"
                  label="キャンセル"
                  onClick={handleClickCancel}
                />
                <Button
                  size="large"
                  label="新規招待"
                  onClick={handleClickInvitation}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
