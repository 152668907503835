import { log } from 'util';

type UseStorageTipProps = {
  organization: string,
  limitGb: number,
  usedGb: number,
};

const svgData = {
  size: {
    width: 200,
    height: 200,
  },
  radius: 80,
  strokeWidth: {
    back: 20,
    front: 14,
  },
};

export const UseStorageTip = (props: UseStorageTipProps) => {
  const { organization, limitGb, usedGb } = props;
  const useRate = usedGb / limitGb;
  const circleLength = Math.PI * svgData.radius * 2;
  const par = Math.round(useRate * 100) || 0;
  const color = (() => {
    if (par <= 70) {
      return '#15A2B4';
    } else if (par <= 80) {
      return '#FFBB04'
    } else if (par <= 100) {
      return '#C00000'
    }
    return '#000000';
  })();
  return (
    <div className="tip_wraper tip_storege">
      <div className="tip box_shadow">
        <h3>
        {(organization) ? (
            <>{organization}<br /></>
        ) : (
          <>{organization}<br /></>
          // <></>
        )}
          ストレージ使用率
        </h3>
        {/* 使用率グラフ（使わない方がやりやすければ消してもらって大丈夫です） */}
        <svg x="0" y="0" width={svgData.size.width} height={svgData.size.height} viewBox={`0 0 ${svgData.size.width} ${svgData.size.height}`}>
          <g transform={`translate(${svgData.size.width / 2} ${svgData.size.height / 2}) rotate(-90)`}>
            <circle
              cx="0"
              cy="0"
              r={svgData.radius}
              fill="none"
              stroke="#D9D9DA"
              strokeWidth={svgData.strokeWidth.back}
            />
            <circle
              cx="0"
              cy="0"
              r={svgData.radius}
              fill="none"
              stroke={color}
              strokeWidth={svgData.strokeWidth.front}
              strokeDasharray={`${circleLength * useRate} ${circleLength - (circleLength * useRate)}`}
              strokeLinecap={usedGb ? 'round' : undefined}
            >
              <animate
                attributeName="stroke-dasharray"
                begin="0"
                dur={`${usedGb / limitGb + 0.3}s`}
                from={`0 ${circleLength}`}
                to={`${circleLength * useRate} ${circleLength - (circleLength * useRate)}`}
              />
            </circle>
          </g>
        </svg>
        <div className="text_box">
          <h4>使用率{par}%</h4>
          <div className="count">{usedGb}<span>GB</span></div>
          <div className="total">/{limitGb}GB</div>
        </div>
      </div>
    </div>
  );
};
