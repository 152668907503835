import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { dialogAction } from '../slices/dialog-slice';

/**
 * 未実装機能関連の処理
 */
export const useUnimplemented = () => {
  const dispatch = useDispatch();

  /**
   * 未実装時のダイアログを表示
   * @param  version 現在のバージョン(a版,β版,v1.2.3など)
   */
  const showMessage = useCallback((version: string) => {
      dispatch(dialogAction.pushMessage({
        title: '未実装',
        message: [
          `本機能は${version}では実装されておりません`,
          `実装完了まで今しばらくお待ちください`,
        ],
        buttons: [{ label: 'OK', callback: () => dispatch(dialogAction.pop()) }],
      }))
    }, []);

  return {
    showMessage,
  }
}
