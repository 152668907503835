import React, { useState, useCallback, useEffect } from 'react';
import { OrganizationEditModal } from '../../ui/modal/organization-edit-modal';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { useDispatch } from 'react-redux';
import { dialogAction } from '../../../slices/dialog-slice';
import { EditTreeView } from '../../../models/edit-tree-view';
import { Leaf, Organization } from '../../../models/edit-tree-view/models';
import { apiCompany, apiIsp, ApiManager } from '../../../managers/api-manager';
import { RoutingPath } from '../../../routes/routing-path';
import { useAppSelector } from '../../../app/hooks';
import { companyAction, CompanyParams } from '../../../slices/company-slice';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { ApiGetCompanyDetailResponse } from '../../../api/api/isp-web/companies/api-get-company-detail.response';
import { ApiGetOrganizationsResponse as ApiGetCompanyOrganizationsResponse } from '../../../api/api/company-web/organizations/api-get-organizations.response';
import { ApiGetOrganizationsResponse as ApiGetIspOrganizationsResponse } from '../../../api/api/isp-web/companies/organizations/api-get-organizations.response';

const ispBreadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.companyList,
  breadcrumbParts.isp.companyDetail,
  breadcrumbParts.isp.organizationManage,
];
const companyBreadcrumbList = [
  breadcrumbParts.company.home,
  breadcrumbParts.company.organizationManage,
];

type Props = {
  companyCode?: string,
} & PageComponentDefaultProps;

export const OrgManagement = (props: Props) => {
  const { apiManger, companyCode } = props;
  const dispatch = useDispatch();
  const [updateFlag, setUpdateFlag] = useState(false);
  const [orgData, setOrgData] = useState<Organization[]>([]);
  const [resolveFlag, setResolveFlag] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [breadcrumbList, setBreadcrumbList] = useState(
    apiManger.type === 'isp' ?
      ispBreadcrumbList :
      companyBreadcrumbList,
  );
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);
  const { breadcrumbName } = useAppSelector((state) => state.company);

  const handleClickEntry = useCallback((v: Leaf | null) => {
    if (isDisabled) return;
    setIsDisabled(true)
    const org = v && orgData.find(elm => elm.organization_code === v.id);
    if (!org) {
      return;
    }
    const data = {
      name: org.organization_name,
      postalCode: org.zip_code,
      addressTop: org.pref_id || 13,
      addressMiddle: org.address,
      addressLast: org.others,
      organizationCode: org.organization_code,
      hierarchy: org.hierarchy,
    };
    apiManger.type === 'isp'
      ? IspLogOperation('organizationManagerAddClick', () => {
        dispatch(dialogAction.push({
          title: '組織登録',
          id: 'orgnization_dialog',
          element: <OrganizationEditModal
            companyCode={companyCode!}
            orgState={data}
            setUpdateFlag={setUpdateFlag}
            type="add"
            apiManger={apiManger.type}
            setIsDisabled={setIsDisabled}
          />,
        }));
      })
      : CompanyLogOperation('organizationManagerAddClick', () => {
        dispatch(dialogAction.push({
          title: '組織登録',
          id: 'orgnization_dialog',
          element: <OrganizationEditModal
            companyCode={companyCode!}
            orgState={data}
            setUpdateFlag={setUpdateFlag}
            type="add"
            apiManger={apiManger.type}
            setIsDisabled={setIsDisabled}
          />,
        }));
      });

  }, [orgData, isDisabled]);
  const handleClickEdit = useCallback((v: Leaf) => {
    if (isDisabled) return;
    setIsDisabled(true)
    const org = orgData.find(elm => elm.organization_code === v.id);
    const _org = org ? org : orgData[0];
    const data = {
      name: _org.organization_name,
      postalCode: _org.zip_code,
      addressTop: _org.pref_id || 13,
      addressMiddle: _org.address,
      addressLast: _org.others,
      organizationCode: _org.organization_code,
      hierarchy: _org.hierarchy,
    };
    apiManger.type === 'isp'
      ? IspLogOperation('organizationManagerEditClick', () => {
        dispatch(dialogAction.push({
          title: '組織変更',
          id: 'orgnization_dialog',
          element: <OrganizationEditModal
            companyCode={companyCode!}
            orgState={data}
            setUpdateFlag={setUpdateFlag}
            type="edit"
            apiManger={apiManger.type}
            setIsDisabled={setIsDisabled}
          />,
        }));
      })
      : CompanyLogOperation('organizationManagerEditClick', () => {
        dispatch(dialogAction.push({
          title: '組織変更',
          id: 'orgnization_dialog',
          element: <OrganizationEditModal
            companyCode={companyCode!}
            orgState={data}
            setUpdateFlag={setUpdateFlag}
            type="edit"
            apiManger={apiManger.type}
            setIsDisabled={setIsDisabled}
          />,
        }));
      });

  }, [orgData, companyCode]);

  const handleClickDelete = useCallback((v: Leaf) => {
    if (isDisabled) return;
    setIsDisabled(true)
    const org = orgData.find(elm => elm.organization_code === v.id);
    if (!org) {
      return;
    }
    apiManger.type === 'isp'
      ? IspLogOperation('organizationManagerDelete', () => {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['この組織を削除してもよろしいですか'],
          buttons: [{
            label: 'キャンセル',
            callback: () => {
              IspLogOperation('organizationManagerDeleteCancel', () => {
                setIsDisabled(false)
                dispatch(dialogAction.pop());
              });
            },
          },
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogAction.pop());
                // ISP管理、組織削除API
                apiIsp.companies(companyCode).organizations(org.organization_code).delete()
                  .then((res) => {
                    setUpdateFlag(!updateFlag);
                  })
                  .catch((e) => {
                    ApiManager.errorFunc(e);
                  })
                  .finally(() => {
                    setIsDisabled(false)
                  })
              },
            }],
        }));
      })
      : CompanyLogOperation('organizationManagerDelete', () => {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['この組織を削除してもよろしいですか'],
          buttons: [{
            label: 'キャンセル',
            callback: () => {
              CompanyLogOperation('organizationManagerDeleteCancel', () => {
                dispatch(dialogAction.pop());
              });
            },
          },
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogAction.pop());
                apiCompany.organizations(org.organization_code).delete()
                  .then((res) => {
                    setUpdateFlag(!updateFlag);
                  })
                  .catch((e) => {
                    ApiManager.errorFunc(e);
                  })
                  .finally(() => {
                    setIsDisabled(false)
                  })
              },
            }],
        }));
      });

  }, [companyCode, orgData]);

  useEffect(() => {
    let unmounted = false;
    if (apiManger.type === 'isp') {
      if (!userInfo.user_id) {
        return;
      }
      // ISP管理、パンくず
      if (!breadcrumbName) {
        (apiIsp.companies(companyCode).get() as Promise<ApiGetCompanyDetailResponse>)
          .then((v) => {
            if (!unmounted) {
              const data: CompanyParams = v.body.data;
              const path = RoutingPath.ispCompanyDetail
                .replace(':companyCode', companyCode!);
              dispatch(companyAction.setBreadcrumbName({
                breadcrumbName: data.company_name,
              }));
              breadcrumbParts.isp.companyDetail.route = path;
              breadcrumbParts.isp.companyDetail.label = data.company_name.length > 20 ?
                data.company_name.slice(0, 20) + '...' :
                data.company_name;
              setBreadcrumbList([
                breadcrumbParts.isp.home,
                breadcrumbParts.isp.companyList,
                breadcrumbParts.isp.companyDetail,
                breadcrumbParts.isp.organizationManage,
              ]);
              setResolveFlag(true);
            }
          });
      } else {
        const path = RoutingPath.ispCompanyDetail
          .replace(':companyCode', companyCode!);
        breadcrumbParts.isp.companyDetail.route = path;
        breadcrumbParts.isp.companyDetail.label = breadcrumbName.length > 20 ?
          breadcrumbName.slice(0, 20) + '...' :
          breadcrumbName;
        setBreadcrumbList([
          breadcrumbParts.isp.home,
          breadcrumbParts.isp.companyList,
          breadcrumbParts.isp.companyDetail,
          breadcrumbParts.isp.organizationManage,
        ]);
        setResolveFlag(true);
      }
    } else {
      setBreadcrumbList(companyBreadcrumbList);
      setResolveFlag(true);
    }
    return () => {
      unmounted = true;
    };
  }, []);
  useEffect(() => {
    let unmounted = false;
    if (apiManger.type === 'isp') {
      if (!userInfo.user_id) {
        return;
      }
      // ISP管理、組織取得
      (apiIsp.companies(companyCode).organizations().get() as Promise<ApiGetIspOrganizationsResponse>)
        .then((v) => {
          if (!unmounted) {
            let data = v.body.data?.filter((v) => {
              return v.is_valid === true;
            });
            setOrgData(data as any);
            // setUpdateFlag(false);
          }
        });
    } else {
      if (!companyUserInfo.member_id) {
        return;
      }
      (apiCompany.organizations().get() as Promise<ApiGetCompanyOrganizationsResponse>)
        .then((v) => {
          if (!unmounted) {
            let data = v.body.data?.filter((v) => {
              return v.is_valid === true;
            });
            setOrgData(data as any);
            // setUpdateFlag(false);
          }
        });
    }
    return () => {
      unmounted = true;
    };
  }, [companyCode, updateFlag]);
  return (
    <div
      id="App"
      className="orgnization"
    >
      <SideBar
        currentPage="company-list"
        apiManger={apiManger.type}
      />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          {breadcrumbName || resolveFlag ?
            <BreadcrumbList breadcrumbList={breadcrumbList} /> :
            <></>}
          <section>
            <header>
              <h2>
                組織管理
              </h2>
            </header>
            <div className="orgnization_box">
              <EditTreeView
                organizations={orgData}
                onClick={(v) => console.log(v)}
                onEntryButtonClick={(v) => handleClickEntry(v)}
                onEditButtonClick={(v) => handleClickEdit(v)}
                onDeleteButtonClick={(v) => handleClickDelete(v)}
              />
            </div>
            {/*<div className="map">/!*★TODO*!/*/}
            {/*  <div className="frame" style={{ width: "250px", height: "250px" }}>*/}
            {/*    <div className="line" style={{ width: "100px", height: "100px", top: "10px", left: "10px" }}>*/}

            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="btn_box">*/}
            {/*    <button className="on">*/}
            {/*      <img src={rangeOff} alt="" />*/}
            {/*      <img src={rangeOn} alt="" className="on" />*/}
            {/*    </button>*/}
            {/*    <button className="">*/}
            {/*      <img src={zoomInOff} alt="" />*/}
            {/*      <img src={zoomInOn} alt="" className="on" />*/}
            {/*    </button>*/}
            {/*    <button className="">*/}
            {/*      <img src={zoomOutOff} alt="" />*/}
            {/*      <img src={zoomOutOn} alt="" className="on" />*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </section>
        </div>
      </div>
    </div>
  );
};
