import { Button } from '../ui/button/button';
import { useDidMount } from '../../hooks/life-cycle';
import { useDispatch } from 'react-redux';
import { dialogAction } from '../../slices/dialog-slice';
import { useState } from 'react';

type MessageDialogProps = {
  message: string[],
  buttons: {
    label: string,
    callback: () => void,
    color?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  }[],
};

const MessageDialogComponent = (props: MessageDialogProps) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);

  const { message, buttons } = props;
  return (
    <>
      <div className="text_box">
        {message.map((v, i) => (
          <div key={`text-dialog-message_${i}`} >{v || <br />}</div>
        ))}
      </div>
      <footer>
        {buttons.map((v, i) => (
          <Button
            key={`message-dialog-button_${i}`}
            size="large"
            disabled={isDisabled}
            label={v.label}
            onClick={() => {
              setIsDisabled(true)
              v.callback()
            }}
            color={v.color}
          />
        ))}
      </footer>
    </>
  );
};

export const MessageDialog = (message: string[], buttons: {
  label: string,
  callback: () => void,
}[]) => <MessageDialogComponent message={message} buttons={buttons} />;
