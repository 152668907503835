import { OutputData } from '@editorjs/editorjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { uuid } from 'uuidv4';
import { useEditorJs } from '../../../hooks/editor-js';
import { Validation } from '../../../managers/validation/validation';
import { createPlaneText } from '../../../utilities/create-plane-text';
import { Tooltip } from '../tooltip/tooltip';
import './rich-text.scss';

type Props = {
  callback: (e: OutputData) => void,
  initData?: OutputData,
  isReadOnly?: boolean,
  validation?: Validation[],
  isAdd?: boolean,
  isEdit?: boolean,
  edgeEle?: globalThis.Window | HTMLElement,
  maxOption?: { towEle: HTMLElement, offset?: number },
  isDialog?: boolean,
}

const dummyData: OutputData = {
  blocks: [{
    data: {
      text: '&nbsp;'
    },
    id: "x-bWFeDxJB",
    type: "paragraph",
  }],
}

export const RichText = (props: Props) => {
  const { isAdd, callback, initData, isReadOnly, validation, isEdit, edgeEle, maxOption, isDialog=true } = props;
  const ref = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null);
  const innnerToolbar = useRef<{ div: HTMLDivElement | null }>({ div: null });
  const [isInit, setIsInit] = useState(false);
  const [_uuid] = useState(uuid());
  const [planeBody, setPlaneBody] = useState('');
  const [isViewValid, setIsViewValid] = useState(false);
  const [focused, setFocused] = useState(false);
  const [enter, setEnter] = useState(false);
  const [isDummy, setIsDummy] = useState(false);
  const [width, setWidth] = useState<number>();
  const [isInit2, setIsInit2] = useState(false);
  const [isLoad, setIsLoad] = useState(false);


  const editor = useEditorJs(ref, (e) => {
    callback(e);
    setPlaneBody(createPlaneText(e));
  }, {
    initData,
    isReadOnly,
  });

  const valid = useMemo(() => {
    const result: string[] = [];
    validation?.forEach((v) => {
      if (!v.test(planeBody)) {
        result.push(...v.errorMessages);
      }
    });
    return result;
  }, [validation, planeBody])

  useEffect(() => {
    if (isAdd) return;
    if (isInit || !initData || !editor.current) return;
    if (!initData.blocks.length) return;
    setIsInit(true);
    editor.current.isReady.then(() => {
      setPlaneBody(createPlaneText(initData));
      editor.current?.render(initData).then(() => {
        setIsLoad(true);
      });
    });
  }, [editor.current, initData])

  useEffect(() => {
    editor.current?.isReady.then(() => {
      const mutate = new MutationObserver(() => {
        if (innnerToolbar.current.div) {
          const selection = getSelection()
          if (!selection) return;
          const rangeCount = selection.rangeCount
          if (rangeCount === 0) {
            return;
          }
          if (rangeCount > 1) {
            for (let i = 1; i < rangeCount; i++) {
              selection.removeRange(selection.getRangeAt(i))
            }
          }
          const caretRange = document.createRange()
          const focusNode = selection.focusNode
          const focusOffset = selection.focusOffset;

          const anchorOffset = selection.anchorOffset;
          const anchorNode = selection.anchorNode;

          const startNode = focusOffset > anchorOffset ? focusNode : anchorNode;
          const startOffset = focusOffset > anchorOffset ? focusOffset : anchorOffset;

          const endNode = focusOffset < anchorOffset ?  anchorNode : focusNode;
          const endOffset = focusOffset < anchorOffset ? anchorOffset : focusOffset;

          if (!startNode || isNaN(startOffset) || !endNode || isNaN(endOffset)) return;
          caretRange.setStart(startNode, startOffset)
          caretRange.setEnd(endNode, endOffset)
          if (!bodyRef.current) return;
          /* 選択箇所の絶対値 */
          const caretStartRect = caretRange.getBoundingClientRect();
          /* 編集ボックスの絶対値 */
          const editBodyRect = bodyRef.current.getBoundingClientRect();
          /* エディター自体のdom(scrollズレ対応用) */
          const editorContainer = document.getElementsByClassName('codex-editor--narrow')[0]!;
          if (!editorContainer) return;
          /* 選択箇所と編集ボックスの絶対値の差分 */
          const diff = {
            x: Math.round(caretStartRect.x - editBodyRect.x),
            y: Math.round(caretStartRect.y - editBodyRect.y),
          };
          /* 数値当て込み */
          let calcLeft = Number(innnerToolbar.current.div.style.left.replace('px', ''));
          /* x座標 ツールバーが見切れ起こす場合右側上限の指定 */
          if ((calcLeft + innnerToolbar.current.div.clientWidth) > (wrapRef.current?.clientWidth ?? 0)) {
            calcLeft = (wrapRef.current?.clientWidth ?? 0) - (innnerToolbar.current.div.clientWidth);
          } 
          /* paddingより左だった場合対応 */
          if (calcLeft < 23) calcLeft = 23;
          innnerToolbar.current.div.style.left = `${calcLeft}px`;

          /* y座標 ツールバーが見切れ起こす場合文字ラインの上側　見切れない時は下に来る様指定 */
          const isOverY = (diff.y + innnerToolbar.current.div.clientHeight + 25.6) > (wrapRef.current?.clientHeight ?? 0)
          let calcTop = `calc(${diff.y}px + ${editorContainer?.scrollTop || 0}px ${isOverY ? '- 3.2em' : '+ 1.6em'})`;
          /* y = 絶対値差分 + スクロール分 + 上下差分(lineheigt基準) */
          innnerToolbar.current.div.style.top = calcTop;
        }
      });
      const toolbarDom = document.getElementsByClassName('ce-inline-toolbar')[0] as HTMLDivElement;
      innnerToolbar.current.div = toolbarDom;
      if (toolbarDom) {
        mutate.observe(toolbarDom, {
          attributes: true,
        })
      }
      if (!isLoad && !initData) {
        setIsLoad(true);
      }
      if (planeBody) {
        editor.current?.readOnly?.toggle(isReadOnly).then(() => {
          if (isDummy) {
            setIsDummy(false);
          }
        });
      }
      else {
        if (isReadOnly) {
          setIsDummy(true);
          editor?.current?.render(dummyData).then(() => {
            editor.current?.readOnly?.toggle(isReadOnly);
          })
        } else {
          if (editor.current?.readOnly.isEnabled !== !!isReadOnly) {
            editor.current?.readOnly?.toggle(isReadOnly).then(() => {
              if (isDummy) {
                editor.current?.clear();
              }
            });
          }
        }
      }
    });
  }, [isReadOnly]);

  useEffect(() => {
    return (() => {
      editor.current?.destroy?.();
    })
  }, [])

  useEffect(() => {
    if (!maxOption || !isLoad) return;
    if (isDialog && isInit2) return;
    if (isDialog && !isInit2) {
      setIsInit2(true);
    }
    const towEleObserver = new ResizeObserver((entries) => {
      const towWidth = entries[0].contentRect.width + (maxOption?.offset || 0);
      setWidth(towWidth);
      towEleObserver.disconnect();
    });
    towEleObserver.observe(maxOption.towEle);
  
  }, [maxOption, isLoad]);


  return (
    <div className="rich_text__wrap" ref={wrapRef}>
      <div
        className={`rich_text${isEdit ? ' edit' : ''}`}
        onFocus={() => {
          setIsViewValid(true);
          setFocused(true);
        }}
        onClick={(e) => {
          if ((e.target as any).contentEditable === 'inherit' && !(e.target as any).isContentEditable && !(e.target as any).target?.id && !(e.target as any)?.pluginType) {
            editor.current?.caret?.setToLastBlock?.('end');
          }
        }}
        onMouseEnter={() => setEnter(true)}
        onMouseLeave={() => setEnter(false)}
        onBlur={() => setFocused(false)}
        style={{
          borderColor: (isViewValid && !!valid.length) ? '#E35D67' : undefined, // #E35D67 .... $error_text_color
          width
        }}
        ref={bodyRef}
      >
        <div id={_uuid} className="rich_text__inner" ref={ref} />
      </div>
      {(isViewValid && !!valid.length && (focused || enter)) &&
        <Tooltip
          relativeEle={wrapRef.current}
          content={<>
            {valid.map((message, i) => (
              <div key={`invalid_message_${i}`} >{message}</div>
            ))}
          </>}
          autoPos={{ h: "left", v: "top" }}
          blowing
          error
          positionType="absolute"
          edgeSupport={edgeEle && { ele: edgeEle }}
        />
      }
    </div>
  )
}