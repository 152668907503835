import { Validation } from '../../../managers/validation/validation';
import { useEffect, useRef, useState } from 'react';

type SelectData = {
  key: string,
  label: string,
};
type SelectProps = {
  value: string,
  list: SelectData[],
  onChange: (v: string) => void,
  placeHolder?: string,
  validations?: Validation[],
  _ref?: any,
} & React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;
const testAll = (v: string, validations: Validation[]) => validations.reduce((pre, validation, i) => {
  const bool = validation.test(v);
  return pre && bool;
}, true);
const getErrorMessagesFromValidations = (validations: Validation[]) => validations.reduce((pre: string[], validation, i) => (!validation.valid ? [...pre, ...validation.errorMessages] : pre), []);

export const Select = (props: SelectProps) => {
  const { _ref, value, list, onChange, placeholder, validations, ...defaultProps } = props;

  const [focused, setFocused] = useState(false);
  const [_value, setValue] = useState<string>(value);
  const [mouseOver, setMouseOver] = useState(false);
  const [valid, setValid] = useState(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [errorContainerHeight, setErrorContainerHeight] = useState(0);
  const [errorContainerWidth, setErrorContainerWidth] = useState(0);
  const errorContainerEle = useRef<HTMLDivElement>(null);
  // - effect -
  // -- errorContainerEle の高さと幅を取得 --
  useEffect(
    () => {
      if (errorContainerEle && errorContainerEle.current) {
        const height = errorContainerEle.current.offsetHeight;
        const width = errorContainerEle.current.offsetWidth;
        setErrorContainerHeight(height);
        setErrorContainerWidth(width);
      }
    },
    [errorContainerEle, errorMessages],
  );

  return (
    <div
      className={`select ${valid ? '' : 'alert'}${defaultProps.className ? ` ${defaultProps.className}` : ''}`}
    >

    <label className="for_select">
      <select
        ref={_ref}
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value);
          e.currentTarget.blur()
        }}
        onFocus={(e) => {
          if (validations) {
            const testAllResult = testAll(String(e.target.value) || _value, validations);
            setValid(testAllResult);
            setErrorMessages(getErrorMessagesFromValidations(validations));
          }
          if (value) setValue(value);
          setFocused(true);
        }}
        onBlur={(e) => {
          if (validations) {
            const testAllResult = testAll(String(e.target.value) || _value, validations);
            setValid(testAllResult);
            setErrorMessages(getErrorMessagesFromValidations(validations));
          }
          setFocused(false);
        }}
        onMouseOver={(e) => {
          // if (validations) {
          //   const testAllResult = testAll(String(e.currentTarget.value), validations);
          //   setValid(testAllResult);
          //   setErrorMessages(getErrorMessagesFromValidations(validations));
          // }
          setMouseOver(true);
        }}
        onMouseLeave={(e) => {
          // if (validations) {
          //   const testAllResult = testAll(String(e.currentTarget.value), validations);
          //   setValid(testAllResult);
          //   setErrorMessages(getErrorMessagesFromValidations(validations));
          // }
          setMouseOver(false);
        }}
      >
        {placeholder && (
          <option
            value={''}
            style={{ display: 'none' }}
          >
            {placeholder}
          </option>
        )}
        {list.map((v, i) => (
          <option
            key={`select-component-option-data_${v.key}_${v.label}_${i}`}
            value={v.key}
          >
            {v.label}
          </option>
        ))}
      </select>
    </label>
      { (!valid && (focused || mouseOver)) && (
        <div
          className="error_pop"
          style={{
            top: -errorContainerHeight - 6,
          }}
          ref={errorContainerEle}
        >
          {errorMessages.map((message, i) => (
            <div key={`invalid_message_${i}`} >{message}</div>
          ))}
        </div>
      ) }
    </div>

  );
};
