import { Validation } from './validation';

export type ValidationPattern =
  'require'
  | 'requireEdit'
  | 'length30'
  | 'length50'
  | 'length80'
  | 'length128'
  | 'length256'
  | 'length1024'
  | 'length2048'
  | 'lengthOver8'
  | 'lengthJust3'
  | 'lengthNum5'
  | 'kana'
  | 'postCode'
  | 'phoneNumber'
  | 'suspensionDate'
  | 'default'
export const ValidationFactory = (
  name: ValidationPattern
) => {
  switch (name) {
    case 'require':
      return new Validation({
        valid: false,
        test: (v) => v.length > 0,
        errorMessages: ['必須項目です'],
      });
    case 'requireEdit':
      return new Validation({
        test: (v) => v.length > 0,
        errorMessages: ['必須項目です'],
      });
    case 'length30':
      return new Validation({
        test: (v) => v.length <= 30,
        errorMessages: ['30文字以下で入力して下さい'],
      });
    case 'length50':
      return new Validation({
        test: (v) => v.length <= 50,
        errorMessages: ['50文字以下で入力して下さい'],
      });
    case 'length80':
      return new Validation({
        test: (v) => v.length <= 80,
        errorMessages: ['80文字以下で入力して下さい'],
      });
    case 'length128':
      return new Validation({
        test: (v) => v.length <= 128,
        errorMessages: ['128文字以下で入力して下さい'],
      });
    case 'length256':
      return new Validation({
        test: (v) => v.length <= 256,
        errorMessages: ['256文字以下で入力して下さい'],
      });
    case 'length1024':
      return new Validation({
        test: (v) => v.length <= 1024,
        errorMessages: ['1024文字以下で入力して下さい'],
      });
    case 'length2048':
      return new Validation({
        test: (v) => v.length <= 2048,
        errorMessages: ['2048文字以下で入力して下さい'],
      });
    case 'lengthOver8':
      return new Validation({
        test: (v) => v.length >= 8,
        errorMessages: ['8文字以上で入力して下さい'],
      });
    case 'lengthJust3':
      return new Validation({
        test: (v) => v.length === 3,
        errorMessages: ['3桁で入力して下さい'],
      });
    case 'lengthNum5':
      return new Validation({
        test: (v) => v.length <= 5,
        errorMessages: ['0~99999までの数字で入力して下さい'],
      });
    case 'kana':
      return new Validation({
        test: (v) => (/^([ァ-ン]|ー)+$/).test(v) === true,
        errorMessages: ['全角カナで入力して下さい'],
      });
    case 'postCode':
      return new Validation({
        test: (v) => (/^[0-9]{3}-[0-9]{4}$/).test(v) === true,
        errorMessages: ['入力内容が正しくありません'],
      });
    case 'phoneNumber':
      return new Validation({
        test: (v) => (/^0\d{1,4}-\d{1,4}-\d{3,4}$/).test(v) === true,
        errorMessages: ['入力内容が正しくありません'],
      });
    case 'suspensionDate':
      return new Validation({
        valid: false,
        test: (v) => v.length > 0,
        errorMessages: ['利用停止日を設定してください'],
      });
    case 'default':
      return new Validation({
        test: (v) => v.length >= 0,
        errorMessages: ['入力内容が正しくありません'],
      });
    default:
      throw new Error('未実装');
  }
};
