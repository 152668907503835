export const ADMIN_COMPANY_NAME = 'Panasonic';

export const APP_NAME = 'P-LOOP';

type CompanyFlagEntity = { label: string, value: number };
type CompanyFlagType = {
  agent: CompanyFlagEntity,
  client: CompanyFlagEntity,
  panasonic: CompanyFlagEntity,
}
export const COMPANY_FLAG: CompanyFlagType = {
  agent: { label: '工務店', value: 1 },
  client: { label: '代理店', value: 2 },
  panasonic: {label: 'パナソニック', value: 3},
};
