import EventEmitter from 'eventemitter3';
import companyWeb from './company-web';
import dataApp from './data-app';
import ispWeb from './isp-web';
import con from '../connection';

export interface IAPIInfo {
  count: number,
}

interface IResponse {
}

export type EventType = {
  success: IResponse,
  'response-error': IResponse,
  'network-error': any,
  start: IAPIInfo,
  progress: IAPIInfo,
  finish: IAPIInfo,
};

export type Mode =
  'local'
  | 'develop'
  | 'stage'
  | 'production'
  ;

type Param = {
  mode: Mode,
  host?: string,
};

export default class API extends EventEmitter<EventType> {

  private readonly mode: Mode;
  private readonly host: string;

  constructor(param: Param) {
    super();
    console.group('[Api Module Constructor]');
    console.log('param : ', param);
    console.groupEnd();
    // - initialize -
    this.mode = param.mode;
    this.host = param.host ? this.checkAndFixInputHost(param.host) : '';
    // - event -
    con.on('success', (e) => {
      this.emit('success', e);
    });
    con.on('response-error', (e) => {
      this.emit('response-error', e);
    });
    con.on('network-error', (e) => {
      this.emit('network-error', e);
    });
    con.on('start', (e) => {
      this.emit('start', e);
    });
    con.on('progress', (e) => {
      this.emit('progress', e);
    });
    con.on('finish', (e) => {
      this.emit('finish', e);
    });
  }

  ispWeb = () => ({ ...this, ...ispWeb({ mode: this.mode, host: this.host }) });

  companyWeb = () => ({ ...this, ...companyWeb({ mode: this.mode, host: this.host }) });

  dataApp = () => ({ ...this, ...dataApp({ mode: this.mode, host: this.host }) });

  getHostName() {
    return this.host;
  }

  private checkAndFixInputHost(host: string) {
    // url チェック
    if (!(
      host.indexOf('http') === 0
      || host.indexOf('https') === 0
      || host.indexOf('.') === 0
    )) {
      throw new Error('URL が正しくありません。');
    }
    // 末尾のスラッシュを削除
    const str = host.replace(/\/$/, '');
    return str;
  }

}
