import {createSlice} from '@reduxjs/toolkit';
import {apiIsp, ApiManager} from '../../managers/api-manager';
import {AppThunk} from '../../app/store';
import {commonDataAction} from '../common-data-slice';

export type IspCommonSlicerState = {};

const initialState: IspCommonSlicerState = {};

const common = apiIsp.common();

export const apiIspCommonSlice = createSlice({
  name: 'api-isp-common',
  initialState,
  reducers: {},
});

const asyncAction = {
  getIndustryList: (): AppThunk => async (dispatch) => {
    try {
      const connectionId = ApiManager.connectStart('get-industry-list');
      const res = await common.industries(1).get();
        const industryList: {
          id: number;
          name: string;
          is_valid: boolean;
        }[] = res.body.data
        dispatch(commonDataAction.setIndustryList(industryList));
        ApiManager.connectEnd(connectionId);
    } catch (e) {
      ApiManager.errorFunc(e);
    }
  },
  getOccupationList: (): AppThunk => async (dispatch) => {
    try {
      const connectionId = ApiManager.connectStart('get-occupation-list');
      const res = await common.occupations().get;
        const occupationList: {
          id: number;
          name: string;
          is_valid: boolean;
        }[] = res.body.data
        dispatch(commonDataAction.setOccupationList(occupationList));
        ApiManager.connectEnd(connectionId);
    } catch (e) {
      ApiManager.errorFunc(e);
    }
  },
};

export const apiIspCommonAction = Object.assign(apiIspCommonSlice.actions, asyncAction);
export const apiIspCommonReducer = apiIspCommonSlice.reducer;
