import con from '../../../../../../connection';

const path = '/download';

const download = (url: string) => () => ({
  // 操作ログ 出力
  get: (param: {
    extract_date_start?: string,
    extract_date_end?: string,
    company_code: string,
    member_id?: string,
    is_stabro?: boolean,
    is_cadect?: boolean,
    is_arepo4b?: boolean,
    is_seacd?: boolean,
    is_arepo4h?: boolean,
    is_dlink?: boolean,
    kind: boolean,
  }) => con.getFile(url + path, param),
});

export default download;
