import React, { useState } from 'react';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { useDidMount } from '../../../hooks/life-cycle';
import iconLogin from '../../../assets/images/common/icon_login.svg';
import iconDownload from '../../../assets/images/common/icon_download.svg';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { useAppSelector } from '../../../app/hooks';
import { apiData } from '../../../managers/api-manager';
import { dialogAction } from '../../../slices/dialog-slice';
import { useDispatch } from 'react-redux';
import { APP_NAME } from '../../../models/foundation';

export type ApiGetV2VersionResponse = {
  header: {
    status: string,
    status_code: number,
    messages: {
      message: string
    }
  },
  body: {
    data: {
      new_version: string,
      is_force: boolean,
      is_valid: boolean,
      is_update: number,
      release_note: [
        {
          note: string,
          version: string,
          created_at: string,
        }[]
      ]
    }
  }
}

export type CompanyUserAddResultProps = {
  companyCode?: string,
} & PageComponentDefaultProps

export const CompanyUserAddResult = (props: CompanyUserAddResultProps) => {
  const dispatch = useDispatch();
  const { companyCode, apiManger } = props;
  const { mode, companyUrl } = useAppSelector((state) => state.serverEnv);
  const [role, setRole] = useState(0);

  const handleClickLogin = () => {
    // ログイン画面を環境ごとに分岐する
    switch (mode) {
      case 'local':
        window.location.href = './#/company/login';
        break;
      case 'develop':
        window.location.href = 'https://isp-cloud-company-web-dev.marietta.co.jp/#/company/login';
        break;
      case 'develop2':
        window.location.href = 'https://isp-cloud-company-web.marietta.co.jp/#/company/login';
        break;
      case 'staging':
        // TODO: 先方環境に合わせて変動させる
        window.location.href = 'https://company-admin.isp-b-loop.com/#/company/login';
        break;
      case 'production':
        // TODO: 先方環境に合わせて変動させる
        window.location.href = 'https://company-admin.isp-b-loop.com/#/company/login';
        break;
      default:
        break;
    }
    if (mode === 'local') {
      window.location.href = './#/company/login';
    } else {
      window.location.href = companyUrl + '/#/company/login';
    }
  };
  const handleClickDl = () => {
    (async () => {
      try {
        dispatch(dialogAction.pushMessage({
          title: 'アプリケーションダウンロード',
          message: ['ダウンロード中です'],
          buttons: [],
        }));
        // 最新バージョン取得処理
        // 新規ユーザはアプリを持っていない想定なので、version('0.0.0')としている。
        const getVersionResponse: ApiGetV2VersionResponse = await apiData.version('0.0.0').get() as any;
        const latestVersion = getVersionResponse?.body?.data?.new_version;
        if (!latestVersion) {
          throw new Error('バージョンチェック処理でエラー !!');
        }
        // インストーラ取得処理
        const installer = await apiData.idms(latestVersion).get();
        if (!(installer instanceof Blob)) {
          throw new Error('インストーラ取得処理でエラー !!');
        }
        // ダウンロード処理
        const anchor = document.createElement('a');
        anchor.download = `${APP_NAME} ${latestVersion}.exe`;
        anchor.href = window.URL.createObjectURL(installer);
        anchor.click();
        // アプリケーションダウンロード正常終了
        dispatch(dialogAction.pop());
        dispatch(dialogAction.pushMessage({
          title: 'アプリケーションダウンロード',
          message: ['ダウンロードが完了しました'],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogAction.pop());
              },
            },
          ],
        }));
      } catch (e) {
        // アプリケーションダウンロード異常終了
        console.error('[error in アプリダウンロード処理]: ', e);
        dispatch(dialogAction.pop());
        dispatch(dialogAction.pushMessage({
          title: 'エラー',
          message: ['ダウンロー中にエラーが発生しました'],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogAction.pop());
              },
            },
          ],
        }));
      }
    })();
  };

  // 権限設定
  useDidMount(() => {
    const isOwner = QueryParamFormatter.queryParse().is_owner;
    if (Number(isOwner)) {
      // オーナーは自動的に管理者に
      setRole(1);
    } else {
      const queryRole = QueryParamFormatter.queryParse().role;
      setRole(Number(queryRole));
    }
  });

  return (
    <>
      {/* Role === １の場合、管理者になります。 role !== 1 で権限分岐できます */}
      {(role === 1) ? (
        <div className="dialog_wrap direct_access result">
          <div className="dialog">
            <header>登録完了</header>
            <div className="dialog_body">
              <div className="inner">
                <div className="text_box">登録が完了しました。</div>
              </div>
              <footer>
                <button
                  className="large primary"
                  onClick={handleClickLogin}
                >
                  <img
                    src={iconLogin}
                    alt={`${APP_NAME}にログイン`}
                  />
                  <span>{APP_NAME}にログイン</span>
                  {/*<span>ISP CLOUDにログイン</span>*/}
                </button>
                <button
                  className="large primary"
                  onClick={handleClickDl}
                >
                  <img
                    src={iconDownload}
                    alt={`${APP_NAME}ポータルアプリDL`}
                  />
                  <span>{APP_NAME}ポータルアプリDL</span>
                </button>
              </footer>
            </div>
          </div>
        </div>
      ) : (
        <div className="dialog_wrap direct_access result">
          <div className="dialog">
            <header>登録完了</header>
            <div className="dialog_body">
              <div className="inner">
                <div className="text_box">登録が完了しました。</div>
              </div>
              <footer>
                <button
                  className="large primary"
                  onClick={handleClickDl}
                >
                  <img
                    src={iconDownload}
                    alt={`${APP_NAME}ポータルアプリDL`}
                  />
                  <span>{APP_NAME}ポータルアプリDL</span>
                </button>
              </footer>
            </div>
          </div>
        </div>
      )
      }
    </>
  );
};
