import { createSlice } from '@reduxjs/toolkit';
import { apiIsp, ApiManager } from '../../managers/api-manager';
import { AppThunk } from '../../app/store';
import { loginIspUserInfoAction } from '../login-user-info-slicer';

export type IspLoginSlicerState = {};

const initialState: IspLoginSlicerState = {};

export const apiIspLoginSlice = createSlice({
  name: 'api-isp-login',
  initialState,
  reducers: {},
});

const asyncAction = {
  login: (data: {
    param: {
      email: string,
      password: string,
    },
    callback: {
      success: () => void,
      failed: () => void,
    },
  }): AppThunk => async (dispatch) => {
    try {
      const res = await apiIsp.login().post({
        email: data.param.email,
        password: data.param.password,
      });
      dispatch(loginIspUserInfoAction.setIspUserDetail({
        userInfo: res.body.data,
      }));
      data.callback.success();
    } catch (e) {
      ApiManager.errorFunc(e);
      data.callback.failed();

    }
  },
};

export const apiIspLoginAction = Object.assign(apiIspLoginSlice.actions, asyncAction);
export const apiIspLoginReducer = apiIspLoginSlice.reducer;
