import islogin from './islogin';
import login from './login';
import logout from './logout';
import profiles from './profiles';
import articles from './articles';
import organizations from './organizations';
import password from './password';
import members from './members';
import { Mode } from '../index';
import users from '../data-app/users';
import requests from './requests';
import icon from './icon';
import applications from './applications';
import accounts from './accounts';
import common from './common';
import logs from './logs';
import version from './version';
import idms from './idms';
import con from '../../connection';
import v2 from './v2';
import exclusive from './exclusive';
import informations from './informations';
import contacts from './contacts';

type Param = {
  mode: Mode,
  host?: string,
};

export type OptionsType = {
  noError?: boolean,
  noNetworkError?: boolean,
  timeoutMS?: number,
};

const dataApp = (param: Param) => {
  const {
    mode,
    host: inputHost,
  } = param;
  // - chose host -
  let host = '';
  switch (mode) {
    case 'local':
      host = 'https://isp-cloud-data-app-dev.marietta.co.jp'
      break;
    case 'develop':
      host = 'https://isp-cloud-data-app-dev.marietta.co.jp'
      break;
    case 'stage':
      host = 'https://isp-cloud-data-app-dev.marietta.co.jp'
      break;
    case 'production':
      host = 'https://isp-cloud-data-app.marietta.co.jp'
      break;
  }
  // - path -
  const path = '/api';
  // - url -
  const url = `${inputHost || host}${path}`;
  return ({
    articles: articles(url),
    icon: icon(url),
    islogin: islogin(url),
    login: login(url),
    logout: logout(url),
    members: members(url),
    organizations: organizations(url),
    password: password(url),
    portal: {},
    profiles: profiles(url),
    requests: requests(url),
    users: users(url),
    applications: applications(url),
    accounts: accounts(url),
    common: common(url),
    logs: logs(url),
    version: version(url),
    idms: idms(url),
    v2: v2(url),
    exclusive: exclusive(url),
    informations: informations(url),
    contacts: contacts(url),
    timeoutTest: (port: number) => con.get(`http://localhost:${port}/timeoutTest`, {}),
  });
};

export default dataApp;
