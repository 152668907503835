import { ApiGetIspInformationsDestinationsResponse } from './api-get-isp-informations-destinations.response';
import con from '../../../../connection';
import upload from './upload/index';

const path = '/destinations';

const destinations = (url: string, info_id?: string) => () => ({
  upload: upload(url + path),
  get: <
   ResponseType extends ApiGetIspInformationsDestinationsResponse | Blob
  >(param?: {
    pref_ids?: [];
    company_codes?: string[];
    created_at_from?: string;
    created_at_to?: string;
    industry_ids?: [];
    occupation_ids?: string[];
    member_created_at_from?: string;
    member_created_at_to?: string;
    roles?: [];
    is_cadect1?: number;
    is_cadect2?: number;
    is_cadect3?: number;
    is_cadect4?: number;
    building_use?: string;
  }) =>
     !info_id
      ? con.get<ResponseType>(url + path, param || {})
      : con.getFile<ResponseType>(`${url}/${info_id}${path}`, {}),
});

export default destinations;