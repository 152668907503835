import React, { useCallback, useMemo } from 'react';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import logo from '../../../assets/images/common/p-loop_logo_menu.png';
import iconPortal from '../../../assets/images/common/icon_portal.svg';
import iconPortalOn from '../../../assets/images/common/icon_portal_on.svg';
import iconCorporate from '../../../assets/images/common/icon_corporate.svg';
import iconCorporateOn from '../../../assets/images/common/icon_corporate_on.svg';
import iconPerson from '../../../assets/images/common/icon_person.svg';
import iconPersonOn from '../../../assets/images/common/icon_person_on.svg';
import iconApp from '../../../assets/images/common/icon_app.svg';
import iconAppOn from '../../../assets/images/common/icon_app_on.svg';
import iconInfo from '../../../assets/images/common/icon_info.svg';
import iconInfoOn from '../../../assets/images/common/icon_info_on.svg';
import iconSystem from '../../../assets/images/common/icon_system.svg';
import iconSystemOn from '../../../assets/images/common/icon_system_on.svg';
import iconLog from '../../../assets/images/common/icon_log.svg';
import iconLogOn from '../../../assets/images/common/icon_log_on.svg';
import iconArticles from '../../../assets/images/common/icon_articles.svg';
import iconArticlesOn from '../../../assets/images/common/icon_articles_on.svg';
import iconClient from '../../../assets/images/common/icon_client.svg';
import iconClientOn from '../../../assets/images/common/icon_client_on.svg';
import { apiIsp } from '../../../managers/api-manager';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { useAppSelector } from '../../../app/hooks';
import { ADMIN_COMPANY_NAME, APP_NAME } from '../../../models/foundation';
import { Mijissou } from '../../../index';
import { dialogAction } from '../../../slices/dialog-slice';
import { Link } from 'react-router-dom';
import { useUnimplemented } from '../../../hooks/use-unimplemented';

type ParentPages = 'portal' | 'company-list' | 'isp-user-list' | 'company-add' | 'isp-user-add' |
  'company-user-list' | 'company-user-add' | 'information' | 'information-list' | 'information-add' |
  'log-Management' | 'articles' | 'clients' | '';

type SidebarProps = {
  currentPage: ParentPages;
  apiManger?: 'isp' | 'company'
};

export const SideBar = (props: SidebarProps) => {
  const { currentPage, apiManger } = props;
  const dispatch = useDispatch();
  const unimplemented = useUnimplemented();
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);

  const logAuth = useMemo(() => {
    if (apiManger === 'company') {
      return companyUserInfo.is_log_admin;
    }
    return true;
  }, [apiManger, companyUserInfo]);
  const infoAuth = useMemo(() => {
    if (apiManger === 'company') {
      return companyUserInfo.is_information;
    }
    return true;
  }, [apiManger, companyUserInfo]);
  /** 管理ポータル遷移 */
  const handleClickPortal = useCallback(
    () => {
      apiManger === 'company' ?
        dispatch(push(RoutingPath.companyStatus))
        :
        IspLogOperation('portalClick', () => {
          dispatch(push(RoutingPath.ispPortal));
        });
    },
    [dispatch],
  );
  /** 企業一覧遷移 */
  const handleClickCompanyList = useCallback(
    () => {
      IspLogOperation('companyListClick', () => {
        dispatch(push(RoutingPath.ispCompanyList));
      });
    },
    [],
  );
  /** 組織管理遷移 */
  const handleClickOrgManagement = useCallback(
    () => {
      CompanyLogOperation('organizationManagerClick', () => {
        dispatch(push(RoutingPath.companyOrgManagement));
      });
    },
    [],
  );
  /** 企業追加遷移 */
  const handlerClickCompanyAdd = useCallback(() => {
    IspLogOperation('companyAddClick', () => {
      dispatch(push(RoutingPath.ispCompanyAdd));
    });
    // Mijissou();
  }, []);
  /** ユーザー一覧遷移 */
  const handleClickUserList = useCallback(
    () => {
      apiManger === 'company' ?
        CompanyLogOperation('companyUserListClick', () => {
          dispatch(push(RoutingPath.companyUserList));
        }) :
        IspLogOperation('ispUserListClick', () => {
          dispatch(push(RoutingPath.ispUserList));
        });
    },
    [],
  );
  /** 物件一覧遷移 */
  const handleClickArticles = useCallback(
    () => {
      CompanyLogOperation('articleManagerClick', () => {
        dispatch(push(RoutingPath.companyArticles));
      });
    },
    [],
  );
  /** ユーザー追加遷移 */
  const handlerClickUserAdd = useCallback(() => {
    apiManger === 'company' ?
      CompanyLogOperation('companyUserAddClick', () => {
        dispatch(push(RoutingPath.companyInvitationUser));
      }) :
      IspLogOperation('ispUserAddClick', () => {
        dispatch(push(RoutingPath.ispInvitationUser));
      });
  }, []);
  /** インフォメーション一覧遷移 */
  const handleClickInfoList = useCallback(() => {
    apiManger === 'company' ?
      CompanyLogOperation('informationClick', () => {
        dispatch(push(RoutingPath.companyInfo));
      }) :
      IspLogOperation('informationClick', () => {
        dispatch(push(RoutingPath.ispInfo));
      });
  }, []);
  /** インフォメーション追加遷移 */
  const handlerClickAddInfo = useCallback(() => {
    apiManger === 'company' ?
      CompanyLogOperation('informationCreateClick', () => {
        dispatch(push(RoutingPath.companyInfoAdd));
      }) :
      IspLogOperation('informationCreateClick', () => {
        dispatch(push(RoutingPath.ispInfoAdd));
      });
  }, []);
  // /** ログ出力遷移 */
  const handlerClickExportLog = useCallback(() => {
    apiManger === 'company' ?
      CompanyLogOperation('logManagerClick', () => {
        dispatch(push(RoutingPath.companyLogExport));
      }) :
      IspLogOperation('logManagerClick', () => {
        dispatch(push(RoutingPath.ispLogExport));
      });
  }, []);

  // -------------------- selected用意 --------------------
  // ポータル
  const portalClass = currentPage === 'portal' ? 'selected' : '';
  // 企業一覧
  const companyListClass = currentPage === 'company-list' || currentPage === 'company-add' ? 'selected' : '';
  // ISPユーザー一覧
  const ispUserListClass = currentPage === 'isp-user-list' || currentPage === 'isp-user-add' ? 'selected' : '';
  // 企業ユーザー一覧
  const companyUserListClass = currentPage === 'company-user-list' || currentPage === 'company-user-add' ? 'selected' : '';
  // 物件一覧
  const articlesClass = currentPage === 'articles' ? 'selected' : '';
  // 取引先一覧
  const clientsClass = currentPage === 'clients' ? 'selected' : '';
  // インフォメーション一覧
  const informationListClass = currentPage === 'information' ? 'selected' : '' || currentPage === 'information-list' || currentPage === 'information-add' ? 'selected' : '';
  // ログ管理
  const logClass = currentPage === 'log-Management' ? 'selected' : '';
  return (
    <div className="side_bar">
      <div className="align_center logo_box">
        <Link
          to="#"
          onClick={handleClickPortal}
        >
          <h1><img
            src={logo}
            style={{ width: '139px' }}
            alt=" ISP CLOUD"
          /></h1>
        </Link>
        <h2>管理メニュー</h2>
      </div>
      <ul className="nav">
        <li className={portalClass}>
          <Link
            to="#"
            onClick={handleClickPortal}
          >
            <img
              src={iconPortal}
              alt={apiManger === 'company' ? '企業管理ポータル' : `${APP_NAME}管理ポータル`}
              className="off"
            />
            <img
              src={iconPortalOn}
              alt={apiManger === 'company' ? '企業管理ポータル' : `${APP_NAME}管理ポータル`}
              className="on"
            />
            {apiManger === 'company' ? '企業管理ポータル' : `${APP_NAME}管理ポータル`}
          </Link>
        </li>
        {apiManger === 'company' ?
          <li className={companyListClass}>
            <Link
              to="#"
              onClick={handleClickOrgManagement}
            >
              <img
                src={iconCorporate}
                alt="組織管理"
                className="off"
              />
              <img
                src={iconCorporateOn}
                alt="組織管理"
                className="on"
              />
              組織管理
            </Link>
          </li>
          :
          <li className={companyListClass}>
            <Link
              to="#"
              onClick={handleClickCompanyList}
            >
              <img
                src={iconCorporate}
                alt="企業管理"
                className="off"
              />
              <img
                src={iconCorporateOn}
                alt="企業管理"
                className="on"
              />
              企業管理
            </Link>
            <ul>
              <li className={currentPage === 'company-list' ? 'selected' : ''}><Link
                to="#"
                onClick={handleClickCompanyList}
              >-&nbsp;企業一覧</Link></li>
              <li className={currentPage === 'company-add' ? 'selected' : ''}><Link
                to="#"
                onClick={handlerClickCompanyAdd}
              >-&nbsp;企業登録</Link></li>
            </ul>
          </li>
        }
        {apiManger === 'company' ?
          <>
            <li className={companyUserListClass}>
              <Link
                to="#"
                onClick={handleClickUserList}
              >
                <img
                  src={iconPerson}
                  alt="ユーザー管理"
                  className="off"
                />
                <img
                  src={iconPersonOn}
                  alt="ユーザー管理"
                  className="on"
                />
                ユーザー管理
              </Link>
              <ul>
                <li className={currentPage === 'company-user-list' ? 'selected' : ''}><Link
                  to="#"
                  onClick={handleClickUserList}
                >-&nbsp;ユーザー一覧</Link></li>
                <li className={currentPage === 'company-user-add' ? 'selected' : ''}><Link
                  to="#"
                  onClick={handlerClickUserAdd}
                >-&nbsp;ユーザー登録</Link></li>
              </ul>
            </li>
            <li className={articlesClass}>
              <Link
                to="#"
                onClick={handleClickArticles}
              >
                <img
                  src={iconArticles}
                  alt="物件管理"
                  className="off"
                />
                <img
                  src={iconArticlesOn}
                  alt="物件管理"
                  className="on"
                />
                物件管理
              </Link>
              <ul>
                <li className={currentPage === 'articles' ? 'selected' : ''}><Link
                  to="#"
                  onClick={handleClickArticles}
                >-&nbsp;物件一覧</Link>
                </li>
              </ul>
            </li>
            <li className={clientsClass}>
              <Link
                to="#"
                onClick={() => unimplemented.showMessage('α版')}
              >
                <img
                  src={iconClient}
                  alt="取引先マスタ"
                  className="off"
                />
                <img
                  src={iconClientOn}
                  alt="取引先マスタ"
                  className="on"
                />
                取引先マスタ
              </Link>
              <ul>
                <li className={currentPage === 'clients' ? 'selected' : ''}>
                  <Link
                    to="#"
                    onClick={() => unimplemented.showMessage('α版')}
                  >-&nbsp;取引先会社一覧</Link>
                </li>
              </ul>
            </li>
          </>
          :
          <li className={ispUserListClass}>
            <Link
              to="#"
              onClick={handleClickUserList}
            >
              <img
                src={iconPerson}
                alt="ISPユーザー管理"
                className="off"
              />
              <img
                src={iconPersonOn}
                alt="ISPユーザー管理"
                className="on"
              />
              {ADMIN_COMPANY_NAME}ユーザー管理
            </Link>
            <ul>
              <li className={currentPage === 'isp-user-list' ? 'selected' : ''}><Link
                to="#"
                onClick={handleClickUserList}
              >-&nbsp;ユーザー一覧</Link></li>
              <li className={currentPage === 'isp-user-add' ? 'selected' : ''}><Link
                to="#"
                onClick={handlerClickUserAdd}
              >-&nbsp;ユーザー登録</Link></li>
            </ul>
          </li>
        }
        <li className="disabled">
          <Link to="#">
            <img
              src={iconApp}
              alt="アプリ管理"
              className="off"
            />
            <img
              src={iconAppOn}
              alt="アプリ管理"
              className="on"
            />
            アプリ管理
          </Link>
        </li>
        <li className={(infoAuth || apiManger !== 'company') ? informationListClass : 'disabled'}>
          <Link
            to="#"
            onClick={handleClickInfoList}
          >
            <img
              src={iconInfo}
              alt="インフォメーション管理"
              className="off"
            />
            <img
              src={iconInfoOn}
              alt="インフォメーション管理"
              className="on"
            />
            インフォメーション管理
          </Link>
          <ul>
            <li className={currentPage === 'information-list' ? 'selected' : ''}>
              <Link
                to="#"
                onClick={handleClickInfoList}
              >-&nbsp;インフォ一覧</Link>
            </li>
            <li className={currentPage === 'information-add' ? 'selected' : ''}>
              <Link
                to="#"
                onClick={handlerClickAddInfo}
              >-&nbsp;インフォ追加</Link>
            </li>
          </ul>
        </li>
        {apiManger === 'company' ?
          <></>
          :
          <li className="disabled">
            <Link to="#">
              <img
                src={iconSystem}
                alt="システム設定"
                className="off"
              />
              <img
                src={iconSystemOn}
                alt="システム設定"
                className="on"
              />
              システム設定
            </Link>
          </li>
        }
        <li className={`${logAuth ? '' : 'disabled '}${logClass}`}>
          <Link
            to="#"
            onClick={handlerClickExportLog}
          >
            <img
              src={iconLog}
              alt="ログ管理"
              className="off"
            />
            <img
              src={iconLogOn}
              alt="ログ管理"
              className="on"
            />
            ログ管理
          </Link>
          {/* <li className={currentPage === 'isp-user-add' ? 'selected' : ''}><Link
                to="#"
                onClick={handlerClickExportLog}
              >-&nbsp;ログ出力</a></li> */}
        </li>
      </ul>
    </div>
  );
};
