import React, { useState, useCallback, useEffect, useRef } from 'react';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useDidMount } from '../../../hooks/life-cycle';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { useAppSelector } from '../../../app/hooks';
import { ispUserAction } from '../../../slices/isp-user-slice';
import { apiIsp, ApiManager } from '../../../managers/api-manager';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { APP_NAME } from '../../../models/foundation';

// バリデーション用意
const validations: { [key: string]: Validation } = {
  requirePassword: ValidationFactory('require'),
  requirePasswordConfirm: ValidationFactory('require'),
  checkPasswordConfirm: ValidationFactory('default'),
  requireEdit: ValidationFactory('requireEdit'),
  length30: ValidationFactory('length30'),
  lengthOver8: ValidationFactory('lengthOver8'),
  lengthOver8Confirm: ValidationFactory('lengthOver8'),
  kana: ValidationFactory('kana'),
};

export const IspUserAdd = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.ispUser.userData);
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
// -------------------- state --------------------
  const [familyName, setFamilyName] = useState('');
  const [fastName, setFastName] = useState('');
  const [familyNameKana, setFamilyNameKana] = useState('');
  const [fastNameKana, setFastNameKana] = useState('');
  const [mail, setMail] = useState('');
  const [mailConfirm, setMailConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [_password, set_Password] = useState('');
  const [token, setToken] = useState('');
  const [userId, setUserId] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);

  // バリデーションのトータルチェック
  // const isDisabled = CheckAllValid(validations)
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  const [validFlag, setValidFlag] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);

// -------------------- イベント --------------------
  const handleChangeLastName = useCallback(
    (v) => {
      setFamilyName(v);
    },
    [],
  );
  const handleChangeFirstName = useCallback(
    (v) => {
      setFastName(v);
    },
    [],
  );
  const handleChangeLastNameKana = useCallback(
    (v) => {
      setFamilyNameKana(v);
    },
    [],
  );
  const handleChangeFirstNameKana = useCallback(
    (v) => {
      setFastNameKana(v);
    },
    [],
  );
  const handleChangePassword = useCallback(
    (v) => {
      setPassword(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
    },
    [],
  );
  const handleChangeCheckPassword = useCallback(
    (v) => {
      set_Password(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
    },
    [],
  );

  const handleClickCheckForm = () => {
    dispatch(ispUserAction.setData({
      token,
      userId,
      familyName,
      fastName,
      familyNameKana,
      fastNameKana,
      mail,
      mailConfirm,
      password,
      _password,
    }));
    const path = RoutingPath.ispUserAddCheck;
    const query = QueryParamFormatter.queryCompose({ token: token });
    dispatch(push(`${path}?${query}`));
  };

  /**　パスワードのバリデーション監視 */
  const checkValidPasswordInput = useCallback(() => {
    if (!passwordRef.current ||
      !passwordConfirmRef.current ||
      passwordConfirmRef.current.value.length <= 0) {
      return;
    }
    passwordConfirmRef.current.focus();
    passwordConfirmRef.current.blur();
    passwordRef.current.focus();
  }, [password]);
  useEffect(() => {
    if (validFlag) {
      checkValidPasswordInput();
    }
  }, [password]);
  // トータルバリデーションチェック
  useEffect(() => {
    setIsDisabled(CheckAllValid(validations));
  }, [
    fastName,
    familyName,
    fastNameKana,
    familyNameKana,
    password,
    _password,
  ]);

  useDidMount(() => {
    // バリデーションの追加
    validations['lengthOver8'] = ValidationFactory('lengthOver8');
    validations['lengthOver8Confirm'] = ValidationFactory('lengthOver8');
    validations['requirePassword'] = ValidationFactory('require');
    validations['requirePasswordConfirm'] = ValidationFactory('require');
    validations['checkPasswordConfirm'] = new Validation({
      test: (v: string) => v === passwordRef.current?.value,
      errorMessages: ['パスワードが一致しません'],
    });
    // トークンチェック
    const token = QueryParamFormatter.queryParse().token;
    apiIsp.users().auth().post({ token }).then((v) => {
        const requestUser = v.body.data;
        setToken(requestUser.token);
        setUserId(requestUser.user_id);
        setFamilyName(requestUser.family_name);
        setFastName(requestUser.name);
        setFamilyNameKana(requestUser.family_name_kana);
        setFastNameKana(requestUser.name_kana);
        setMail(requestUser.email);
        setMailConfirm(requestUser.email);
        setErrorFlag(true);
    }).catch((e) => {
      ApiManager.errorFunc(e)
      setErrorFlag(false);
      dispatch(push(RoutingPath.ispErrorToken));
    })
    setToken(user.token);
    setUserId(user.userId);
    setFamilyName(user.familyName);
    setFastName(user.fastName);
    setFamilyNameKana(user.familyNameKana);
    setFastNameKana(user.fastNameKana);
    setValidFlag(true);
  });

  return (
    !errorFlag ?
      <></> :
      <div className="dialog_wrap direct_access add">
        <div className="dialog">
          <header>
            <div>{APP_NAME}に招待されました。</div>
            <div>以下情報を入力し、会員登録を完了させてください。</div>
          </header>

          <div className="dialog_body">
            <div className="edit_wrap">
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">ユーザー名<span className="required">必須</span></div>
                  <div className="item_cnt flex">
                    <div style={{ marginRight: '30px' }}>
                      <label className="fix_size">姓</label>
                      <Input
                        value={familyName}
                        onChange={(e) => handleChangeLastName(e.target.value)}
                        validations={[
                          validations.requireEdit,
                          validations.length30,
                        ]}
                      />
                    </div>
                    <div>
                      <label className="fix_size">名</label>
                      <Input
                        value={fastName}
                        onChange={(e) => handleChangeFirstName(e.target.value)}
                        validations={[
                          validations.requireEdit,
                          validations.length30,
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">ユーザー名（カナ）<span className="required">必須</span></div>
                  <div className="item_cnt flex">
                    <div style={{ marginRight: '30px' }}>
                      <label className="fix_size">セイ</label>
                      <Input
                        value={familyNameKana}
                        onChange={(e) => handleChangeLastNameKana(e.target.value)}
                        validations={[
                          validations.requireEdit,
                          validations.length30,
                          validations.kana,
                        ]}
                      />
                    </div>
                    <div>
                      <label className="fix_size">メイ</label>
                      <Input
                        value={fastNameKana}
                        onChange={(e) => handleChangeFirstNameKana(e.target.value)}
                        validations={[
                          validations.requireEdit,
                          validations.length30,
                          validations.kana,
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">メールアドレス</div>
                  <div className="item_cnt">
                    <label className="text_box">{mail}</label>
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">パスワード<span className="required">必須</span></div>
                  <div className="item_cnt">
                    <Input
                      type={'password'}
                      value={password}
                      onChange={(e) => handleChangePassword(e.target.value)}
                      validations={[
                        validations.requirePassword,
                        validations.lengthOver8,
                      ]}
                      ref={passwordRef}
                    />
                    <span className="comment">※半角英数８文字以上</span>
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">パスワード（確認）<span className="required">必須</span></div>
                  <div className="item_cnt">
                    <Input
                      type={'password'}
                      value={_password}
                      onChange={(e) => handleChangeCheckPassword(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.currentTarget.blur();
                          handleClickCheckForm();
                        }
                      }}
                      validations={[
                        validations.requirePasswordConfirm,
                        validations.lengthOver8Confirm,
                        validations.checkPasswordConfirm,
                      ]}
                      ref={passwordConfirmRef}
                    />
                  </div>
                </div>
              </div>
            </div>
            <footer>
              <Button
                size="large"
                color="primary"
                label={'入力確認'}
                onClick={handleClickCheckForm}
                disabled={isDisabled}
              />
            </footer>
          </div>
        </div>
      </div>
  );
};
