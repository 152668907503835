import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiIsp, ApiManager } from '../../managers/api-manager';

export type IspCompaniesSlicerState = {};

const initialState: IspCompaniesSlicerState = {};

const companies = apiIsp.companies();

export const apiIspCompaniesSlice = createSlice({
  name: 'api-isp-companies',
  initialState,
  reducers: {
    register: (state, action: PayloadAction<{
      param: {
        company_code: string;
        company_name: string;
        company_name_kana: string;
        industry_id: number;
        occupation_id: number;
        zip_code: string;
        pref_id: number;
        address: string;
        others: string;
        tel: string;
        owner_family_name: string;
        owner_name: string;
        owner_family_name_kana: string;
        owner_name_kana: string;
        email: string;
        storage_size: number;
        member_use_count: number;
        info_use_count: number;
        log_use_count: number;
        cadect1_use_count: number;
        cadect2_use_count: number;
        cadect3_use_count: number;
        cadect4_use_count: number;
        scheduled_to_stop_at?: string | null | undefined;
        pass: string,
      },
      callback: {
        success: () => void,
        failed: () => void,
      },
    }>) => {
      companies.post(action.payload.param)
        .then((res) => {
          action.payload.callback.success();
        })
        .catch((e) => {
          ApiManager.errorFunc(e);
          action.payload.callback.failed();
        });
    },
    update: (state, action: PayloadAction<{
      companyCode: string,
      param: {
        company_code: string;
        company_name: string;
        company_name_kana: string;
        industry_id: number;
        occupation_id: number;
        zip_code: string;
        pref_id: number;
        address: string;
        others: string;
        tel: string;
        storage_size: number;
        member_use_count: number;
        info_use_count: number;
        log_use_count: number;
        cadect1_use_count: number;
        cadect2_use_count: number;
        cadect3_use_count: number;
        cadect4_use_count: number;
        scheduled_to_stop_at?: string | null | undefined;
        pass: string,
      },
      callback: {
        success: () => void,
        failed: () => void,
      },
    }>) => {
      apiIsp.companies(action.payload.companyCode).post(action.payload.param)
        .then((res) => {
          action.payload.callback.success();
        })
        .catch((e) => {
          ApiManager.errorFunc(e);
          action.payload.callback.failed();
        });
    },
  },
});

export const apiIspCompaniesAction = apiIspCompaniesSlice.actions;
export const apiIspCompaniesReducer = apiIspCompaniesSlice.reducer;
