import React, { useState, useCallback, useRef, useEffect } from 'react';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { Button } from '../../ui/button/button';
import { Input } from '../../ui/input/input';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { useDidMount } from '../../../hooks/life-cycle';
import { apiIsp, apiData, ApiManager } from '../../../managers/api-manager';
import { dialogAction } from '../../../slices/dialog-slice';
import { companyUserAction } from '../../../slices/company-user-slice';
import { useAppSelector } from '../../../app/hooks';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { Checkbox } from '../../ui/checkbox/checkbox';
import { Link } from 'react-router-dom';
import { APP_NAME } from '../../../models/foundation';

type CompanyUserAddProps = {
  companyCode?: string,
} & PageComponentDefaultProps;

// バリデーション用意
const validations: { [key: string]: Validation } = {
  requireFamilyName: ValidationFactory('require'),
  requireFastName: ValidationFactory('require'),
  requireFamilyNameKana: ValidationFactory('require'),
  requireFastNameKana: ValidationFactory('require'),
  requirePassword: ValidationFactory('require'),
  requirePasswordConfirm: ValidationFactory('default'),
  checkPasswordConfirm: ValidationFactory('default'),
  length30: ValidationFactory('length30'),
  lengthOver8: ValidationFactory('lengthOver8'),
  lengthOver8Confirm: ValidationFactory('lengthOver8'),
  kana: ValidationFactory('kana'),
};

export const CompanyUserAdd = (props: CompanyUserAddProps) => {
  const { companyCode, apiManger } = props;
  const dispatch = useDispatch();
  const companyUser = useAppSelector((state) => state.companyUser.companyUserData);
  const rulesUrl = useAppSelector((state) => state.serverEnv.rulesUrl);
  // -------------------- state --------------------
  const [companyName, setCompanyName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [isOwner, setIsOwner] = useState(0);
  const [familyName, setFamilyName] = useState('');
  const [fastName, setFastName] = useState('');
  const [familyNameKana, setFamilyNameKana] = useState('');
  const [fastNameKana, setFastNameKana] = useState('');
  const [password, setPassword] = useState('');
  const [_password, set_Password] = useState('');
  const [isKindUser, setIsKindUser] = useState(true);
  const [token, setToken] = useState('');
  const [memberId, setMemberId] = useState('');
  const [mail, setMail] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);
  const [termCheck, setTermCheck] = useState(false);

  // バリデーションのトータルチェック
  // const isDisabled = CheckAllValid(validations)
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  const [validFlag, setValidFlag] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);

  // -------------------- イベント --------------------
  const handleChangeFamilyName = useCallback(
    (v) => {
      setFamilyName(v);
    },
    [],
  );
  const handleChangeFastName = useCallback(
    (v) => {
      setFastName(v);
    },
    [],
  );
  const handleChangeFamilyNameKana = useCallback(
    (v) => {
      setFamilyNameKana(v);
    },
    [],
  );
  const handleChangeFastNameKana = useCallback(
    (v) => {
      setFastNameKana(v);
    },
    [],
  );
  const handleChangePassword = useCallback(
    (v) => {
      setPassword(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
    },
    [],
  );
  const handleChangeCheckPassword = useCallback(
    (v) => {
      set_Password(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
    },
    [],
  );

  const handleClickCheckForm = () => {
      if (!isKindUser) {
        // 既存ユーザー招待時
        apiData.users().activate().post({
          token: token,
          kind: isKindUser,
          member_id: memberId,
          email: mail,
          is_owner: !!Number(isOwner),
        }).then((v) => {
          if (apiManger.type === 'isp') {
            const path = RoutingPath.ispCompanyUserAddResult
              .replace(':companyCode', companyCode!);
            const queryRole = QueryParamFormatter.queryCompose({ role: v.body.data.role });
            dispatch(push(`${path}?${queryRole}`));
          } else {
            const path = RoutingPath.companyUserAddResult;
            const queryRole = QueryParamFormatter.queryCompose({ role: v.body.data.role });
            dispatch(push(`${path}?${queryRole}`));
          }
        })
          .catch((e) => {
            ApiManager.errorFunc(e);
          });
        return;
      }
      dispatch(companyUserAction.setData({
        member_id: memberId,
        email: mail,
        company_code: companyCode ?? '',
        company_name: companyName,
        organization_name: organizationName,
        token,
        kind: isKindUser,
        familyName,
        fastName,
        familyNameKana,
        fastNameKana,
        password,
        _password,
      }));

      if (!Number(isOwner)) {
        // オーナー以外の登録
        if (apiManger.type === 'isp') {
          const path = RoutingPath.ispCompanyUserAddCheck
            .replace(':companyCode', companyCode!);
          const queryToken = QueryParamFormatter.queryCompose({ token: token });
          const queryIsOwner = QueryParamFormatter.queryCompose({ is_owner: isOwner });
          dispatch(push(`${path}?${queryToken}&${queryIsOwner}`));
        } else {
          const path = RoutingPath.companyUserAddCheck;
          const queryToken = QueryParamFormatter.queryCompose({ token: token });
          const queryIsOwner = QueryParamFormatter.queryCompose({ is_owner: isOwner });
          dispatch(push(`${path}?${queryToken}&${queryIsOwner}`));
        }
      }
      if (Number(isOwner)) {
        // オーナーの登録（パスワードのみ。確認なし。）
        setIsDisabled(true)
        apiData.users().activate().post({
          token: token,
          kind: isKindUser,
          member_id: memberId,
          email: mail,
          is_owner: !!Number(isOwner),
          password,
        }).then((v) => {
          if (apiManger.type === 'isp') {
            const path = RoutingPath.ispCompanyUserAddResult
              .replace(':companyCode', companyCode!);
            const queryIsOwner = QueryParamFormatter.queryCompose({ is_owner: isOwner });
            dispatch(push(`${path}?${queryIsOwner}`));
          } else {
            const path = RoutingPath.companyUserAddResult;
            const queryIsOwner = QueryParamFormatter.queryCompose({ is_owner: isOwner });
            dispatch(push(`${path}?${queryIsOwner}`));
          }
        })
          .catch((e) => {
            ApiManager.errorFunc(e);
            setIsDisabled(false)
          });
      }
    };

  /**　パスワードのバリデーション監視 */
  const checkValidPasswordInput = useCallback(() => {
   if (!passwordRef.current ||
   !passwordConfirmRef.current ||
     passwordConfirmRef.current.value.length <= 0) {
     return;
   }
    passwordConfirmRef.current.focus();
    passwordConfirmRef.current.blur();
    passwordRef.current.focus();
  }, [password]);
  /** パスワードの変更を検知 */
  useEffect(() => {
    if (validFlag) {
      checkValidPasswordInput();
    }
  }, [password]);
  // トータルバリデーションチェック
  useEffect(() => {
    setIsDisabled(CheckAllValid(validations));
  }, [
    fastName,
    familyName,
    fastNameKana,
    familyNameKana,
    password,
    _password,
  ]);

  useDidMount(() => {
    // ルーティング調整
    const token = QueryParamFormatter.queryParse().token;
    const query = QueryParamFormatter.queryParse().is_owner;
    setIsOwner(query);
    // トークンチェック
    apiData.users().auth().post({ token }).then((v) => {
      setCompanyName(v.body.data.company_name);
      setMail(v.body.data.email);
      setIsKindUser(v.body.data.kind);
      setMemberId(v.body.data.member_id);
      setOrganizationName(v.body.data.organization_name);
      setToken(v.body.data.token);
      setErrorFlag(true);
    })
      .catch((e) => {
        setErrorFlag(false);
        dispatch(push(apiManger.type === 'isp' ?
          RoutingPath.ispErrorToken :
          RoutingPath.companyErrorToken));
      });
    setFamilyName(companyUser.familyName);
    setFastName(companyUser.fastName);
    setFamilyNameKana(companyUser.familyNameKana);
    setFastNameKana(companyUser.fastNameKana);
  });

  useEffect(() => {
    setFamilyName('');
    setFastName('');
    setFamilyNameKana('');
    setFastNameKana('');
    setPassword('');
    set_Password('');
    if (Number(isOwner)) {
      delete validations.length30;
      delete validations.kana;
      delete validations.requireFamilyName;
      delete validations.requireFamilyNameKana;
      delete validations.requireFastName;
      delete validations.requireFastNameKana;
    }
    validations['lengthOver8'] = ValidationFactory('lengthOver8');
    validations['lengthOver8Confirm'] = ValidationFactory('lengthOver8');
    validations['requirePassword'] = ValidationFactory('require');
    validations['requirePasswordConfirm'] = ValidationFactory('require');
    validations['checkPasswordConfirm'] = new Validation({
      test: (v: string) => v === passwordRef.current?.value,
      errorMessages: ['パスワードが一致しません'],
    });
    setValidFlag(true);
  }, [isOwner]);
  return (
    !errorFlag ?
      <></> :
      <>
        {/* 下記 isKindUser を !isKindUser に変更すると権限変更できます */}
        {(isKindUser) ?
          <div className="dialog_wrap direct_access add">
            <div className="dialog">
              <header>
                <div>{APP_NAME}に招待されました。</div>
                <div>以下情報を入力し、会員登録を完了させてください。</div>
              </header>

              <div className="dialog_body">
                <div className="company_info_box">
                  <div>企業名：{companyName}</div>
                  <div>組織名：{organizationName}</div>
                </div>

                <div className="edit_wrap">
                  <div className="edit_box">
                    {Number(isOwner) ? (
                      <></>
                    ) : (
                      <>
                        <div className="item_wrap">
                          <div className="item_head">ユーザー名
                            <span className="required">必須</span>
                          </div>
                          <div className="item_cnt flex">
                            <div style={{ marginRight: '30px' }}>
                              <label className="fix_size">姓</label>
                              <Input
                                value={familyName}
                                onChange={(e) => handleChangeFamilyName(e.target.value)}
                                validations={[
                                  validations.requireFamilyName,
                                  validations.length30,
                                ]}
                              />
                            </div>
                            <div>
                              <label className="fix_size">名</label>
                              <Input
                                value={fastName}
                                onChange={(e) => handleChangeFastName(e.target.value)}
                                validations={[
                                  validations.requireFastName,
                                  validations.length30,
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="item_wrap">
                          <div className="item_head">ユーザー名（カナ）
                            <span className="required">必須</span>
                          </div>
                          <div className="item_cnt flex">
                            <div style={{ marginRight: '30px' }}>
                              <label className="fix_size">セイ</label>
                              <Input
                                value={familyNameKana}
                                onChange={(e) => handleChangeFamilyNameKana(e.target.value)}
                                validations={[
                                  validations.requireFamilyNameKana,
                                  validations.length30,
                                  validations.kana,
                                ]}
                              />
                            </div>
                            <div>
                              <label className="fix_size">メイ</label>
                              <Input
                                value={fastNameKana}
                                onChange={(e) => handleChangeFastNameKana(e.target.value)}
                                validations={[
                                  validations.requireFastNameKana,
                                  validations.length30,
                                  validations.kana,
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    <div className="item_wrap">
                      <div className="item_head plus_comment">
                        <div> パスワード<br />
                          <span className="comment">※半角英数８文字以上</span>
                        </div>
                        <span className="required">必須</span>
                      </div>
                      <div className="item_cnt">
                        <Input
                          type={'password'}
                          value={password}
                          boxSize="large"
                          onChange={(e) => handleChangePassword(e.target.value)}
                          validations={[
                            validations.requirePassword,
                            validations.lengthOver8,
                          ]}
                          ref={passwordRef}
                        />
                      </div>
                    </div>
                    <div className="item_wrap">
                      <div className="item_head">パスワード（確認）
                        <span className="required">必須</span>
                      </div>
                      <div className="item_cnt">
                        <Input
                          type={'password'}
                          value={_password}
                          boxSize="large"
                          onChange={(e) => handleChangeCheckPassword(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              e.currentTarget.blur();
                              handleClickCheckForm();
                            }
                          }}
                          validations={[
                            validations.requirePasswordConfirm,
                            validations.lengthOver8Confirm,
                            validations.checkPasswordConfirm,
                          ]}
                          ref={passwordConfirmRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb_60 v_center_box gap_sm">
                    <a target="_blank" href={rulesUrl} rel="noopener noreferrer" style={{textDecoration: 'underline'}}>{APP_NAME}利用規約</a>
                    <div>
                      <Checkbox checked={termCheck} label="BLOOP利用規約に同意します。" id="term-check" onClick={() => setTermCheck(!termCheck)}></Checkbox>
                    </div>
                  </div>
                </div>
                <footer>
                  <Button
                    size="large"
                    color="primary"
                    label={Number(isOwner) ? 'パスワード設定' : '入力確認'}
                    onClick={handleClickCheckForm}
                    disabled={isDisabled || !termCheck}
                  />
                </footer>
              </div>
            </div>
          </div>
          :
          <div className="dialog_wrap direct_access add">
            <div className="dialog">
              <header>以下情報で{APP_NAME}に招待されました。</header>
              <div className="dialog_body">
                <div className="edit_wrap">
                  <div className="edit_box">
                    <div className="item_wrap">
                      <div className="item_head">企業名</div>
                      <div className="item_cnt">
                        <label className="text_box">
                          {companyName}
                        </label>
                      </div>
                    </div>
                    <div className="item_wrap">
                      <div className="item_head">組織名</div>
                      <div className="item_cnt">
                        <label className="text_box">
                          {organizationName}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <footer>
                  <Button
                    size="large"
                    color="primary"
                    label={'招待を受ける'}
                    onClick={handleClickCheckForm}
                  />
                </footer>
              </div>
            </div>
          </div>
        }
      </>
  );
};
